import {
  USER_CONTRIBUTION_FAIL,
  USER_CONTRIBUTION_REQUEST,
  USER_CONTRIBUTION_SUCCESS,
} from "../constants/contributionConstants";

export const sendContributionReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONTRIBUTION_REQUEST:
      return { loading: true };
    case USER_CONTRIBUTION_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_CONTRIBUTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
