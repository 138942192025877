import React, { useEffect, useState } from "react";
import axios from "../../node_modules/axios/index";
import UserPicture from "../components/UserPicture";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import { useMediaQuery } from "react-responsive";
import LoadingBox from "../components/LoadingBox";
import { useAuth } from "../AuthContext";
import Rating from "../components/Rating";
import ThumbnailVideoNoLike from "../components/ThumbnailVideoNoLike";
import LaunchVideo from "../components/LaunchVideo";

export default function ListScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const creatorId = props.match.params.id;

  const [creatorList, setCreatorList] = useState("");
  const [videoPlayed, setVideoPlayed] = useState("");
  const [channelPlayed, setChannelPlayed] = useState("");
  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    const fetchData = async (channelIds) => {
      const token = currentUser?.auth.currentUser.accessToken || "";

      const data = {
        channelIds,
        token,
      };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS + "creatorlists/getcreatorlists",
          data
        );

        setCreatorList(response.data[0]);

        return response.data; // Return the fetched data
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );
        throw error; // Rethrow or handle error as needed
      }
    };

    fetchData([creatorId]);
  }, []);

  const { currentUser } = useAuth();

  const [channels, setChannels] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    setLoadingList(true);
    if (creatorList?.list) {
      const creatorListOnly = creatorList.list
        .map(({ channelId }) => channelId)
        .join(",");

      async function getChannels() {
        let responseRanking = await axios.get(
          process.env.REACT_APP_API_ADDRESS +
            `products/channelsforlists/${creatorListOnly}/getvideos/true`
        );
        setChannels(responseRanking.data);
      }

      getChannels();
    }
    setLoadingList(false);
  }, [creatorList]);

  useEffect(() => {
    if (creatorList?.uid) {
      async function getUser() {
        let response = await axios.get(
          process.env.REACT_APP_API_ADDRESS + `users/uid/${creatorList.uid}`
        );
        const userName = response.data[0].name;
        const userId = response.data[0]._id;

        setUserId(userId);
        setUserName(userName);
      }
      getUser();
    }
  }, [creatorList]);

  const isMobile = useMediaQuery({
    query: "(min-width: 430px)",
  });

  const listsToIndex = ["65fd8df698555c0041a30af6", "663b8196433b7b00604f59dd"];

  return (
    <div>
      <Helmet>
        <title>
          {creatorList?.listName
            ? creatorList.listName + " | Favoree YouTube channel list"
            : "YouTube channel list | Favoree"}
        </title>
        <meta
          name="description"
          content={
            creatorList && userName
              ? creatorList.description
              : "Channel list created by this user."
          }
        ></meta>

        <link
          rel="canonical"
          href={
            listsToIndex.includes(creatorId)
              ? "https://www.favoree.io/list/" + creatorId
              : "https://www.favoree.io/list/65fc3fce284ea2004121f454"
          }
        ></link>
      </Helmet>
      <div className="space"></div>
      <div className="space"></div>
      <div className="mainElement" id="listPage">
        {creatorList?.listName ? (
          <div className="alignVertical">
            <div
              class="image-container"
              style={{ "max-width": "calc(100vw - 4rem" }}
            >
              {channels?.length > 0
                ? channels.map((c) => (
                    <img
                      loading="lazy"
                      className="smallRound"
                      src={c.profileImage}
                    ></img>
                  ))
                : [0, 1, 2, 3, 4].map((c) => (
                    <div
                      className="smallRound"
                      style={{
                        "background-color": "grey",
                        display: "inline-block",
                        "margin-left": "5px",
                        "margin-top": "0rem",
                      }}
                    ></div>
                  ))}
              {channels?.length > 5 && <div class="image-fade"></div>}
            </div>
            {isMobile && <div className="space"></div>}
            <h1>{creatorList.listName}</h1>
            <div className="alignHorizontal">
              <a href={"/user/" + userId}>
                <UserPicture
                  uid={creatorList.uid}
                  imageSize="reallySmallRound"
                ></UserPicture>
              </a>
              <text
                id="grey"
                style={{
                  "margin-left": "1rem",
                  "margin-right": "0.5rem",
                }}
              >
                {"by " + " "}{" "}
              </text>
              <a href={"/user/" + userId}>
                <strong id="italic"> {userName}</strong>
              </a>
              <text
                id="grey"
                style={{
                  "margin-left": "1rem",
                }}
              >
                - {channels?.length} {isMobile && "YouTube"} channels
              </text>
            </div>
            <div className="space"></div>
            <p style={{ "max-width": "70rem" }}>{creatorList.description}</p>
            {currentUser?.uid == creatorList?.uid && (
              <a
                href={`/create-list/${creatorList._id}`}
                className="editButton"
              >
                <div className="space"></div> <div className="space"></div>
                <button id="transparentButton">
                  <i className="fa-solid fa-pen-to-square"></i> Edit list
                </button>
              </a>
            )}
            {creatorList?.private && (
              <div>
                <div className="space"></div>
                <strong>🚫</strong>

                <text id="italic">
                  {" "}
                  This list is private, only you can see it
                </text>
              </div>
            )}
            <div className="space"></div>
            <div className="space"></div>
            <div class="creatorList">
              {channels?.length > 0 && !loadingList ? (
                channels.map((c) => (
                  <div className="channelElement" id={c._id}>
                    <div className="alignVertical" style={{ margin: "auto" }}>
                      <a
                        href={
                          c.allTopics[0]
                            ? `/channel/${c.name
                                .replaceAll(" ", "_")
                                .replaceAll("-", "_")
                                .toLowerCase()}-${c._id}`
                            : "https://www.youtube.com/channel/" + c.channelId
                        }
                      >
                        <img
                          loading="lazy"
                          className="smallRound"
                          src={c.profileImage}
                        ></img>
                      </a>
                      <div className="space"></div>

                      <a
                        href={
                          c.allTopics[0]
                            ? `/channel/${c.name
                                .replaceAll(" ", "_")
                                .replaceAll("-", "_")
                                .toLowerCase()}-${c._id}`
                            : "https://www.youtube.com/channel/" + c.channelId
                        }
                        style={{ "margin-left": "auto" }}
                      >
                        <h3>{c.name}</h3>
                      </a>
                      <div className="alignHorizontal">
                        <Rating
                          mean={true}
                          rating={c.rating}
                          numReviews={c.numReviews}
                        ></Rating>
                      </div>
                      <div className="space"></div>

                      <text id="grey">
                        {(c.subscriberCount > 1000000
                          ? Math.round(c.subscriberCount / 100000) / 10 + "M"
                          : c.subscriberCount > 10000
                          ? Math.round(c.subscriberCount / 1000) + "K"
                          : c.subscriberCount > 1000
                          ? Math.round(c.subscriberCount / 100) / 10 + "K"
                          : c.subscriberCount) +
                          " subcribers - " +
                          (c.viewCount > 1000000000
                            ? Math.round(c.viewCount / 100000000) / 10 + "B"
                            : c.viewCount > 1000000
                            ? Math.round(c.viewCount / 100000) / 10 + "M"
                            : c.viewCount > 10000
                            ? Math.round(c.viewCount / 1000) + "K"
                            : c.viewCount > 1000
                            ? Math.round(c.viewCount / 100) / 10 + "K"
                            : c.viewCount) +
                          " views"}
                      </text>
                      <div className="space"></div>
                      <div className="alignHorizontal">
                        <a
                          class="giveReview"
                          href={
                            c.link[0] ||
                            "https://www.youtube.com/channel/" + c.channelId
                          }
                          id="red"
                          target="_blank"
                        >
                          <i class="fa-brands fa-youtube"></i> YouTube
                        </a>{" "}
                        {c.allTopics[0] && (
                          <a
                            href={
                              c.allTopics[0]
                                ? `/channel/${c.name
                                    .replaceAll(" ", "_")
                                    .replaceAll("-", "_")
                                    .toLowerCase()}-${c._id}`
                                : "https://www.youtube.com/channel/" +
                                  c.channelId
                            }
                            class="giveReview"
                            id="greyBG"
                          >
                            <i class="fa-solid fa-star"></i> Channel reviews{" "}
                          </a>
                        )}
                      </div>
                    </div>
                    <div
                      className="alignHorizontal"
                      style={{
                        margin: "auto",
                        width: "100%",
                        "max-width": "323px",
                      }}
                    >
                      {c.lastVideos &&
                        c.lastVideos
                          .filter((a) => a.nbViews >= 10)
                          .sort(
                            (a, b) =>
                              new Date(b.publishDate) - new Date(a.publishDate)
                          )
                          .slice(0, 1)
                          .map((x) => (
                            <ThumbnailVideoNoLike
                              c={x}
                              setVideoPlayed={setVideoPlayed}
                              setChannelPlayed={setChannelPlayed}
                              channelName={c.name}
                              channelId={c._id}
                            ></ThumbnailVideoNoLike>
                          ))}
                    </div>
                  </div>
                ))
              ) : (
                <div className="row top">
                  <LoadingBox></LoadingBox>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                </div>
              )}
              {channels?.length > 5 && <div class="image-fade"></div>}
            </div>
            {videoPlayed && (
              <LaunchVideo
                videoPlayed={videoPlayed}
                channelPlayed={channelPlayed}
                setVideoPlayed={setVideoPlayed}
                setChannelPlayed={setChannelPlayed}
              ></LaunchVideo>
            )}
          </div>
        ) : (
          <div className="row top">
            <LoadingBox></LoadingBox>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
          </div>
        )}
      </div>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
}
