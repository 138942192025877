import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../node_modules/axios/index";
import {
  createLikeReview,
  deleteLikeReview,
} from "../actions/likeReviewActions";
import PopUp from "./PopUp";
import PopUpMessage from "./PopUpMessage";

export default function LikeCountReview(props) {
  const { reviewId, userToken, userIdReviewing, uid } = props;

  const dispatch = useDispatch();

  const [totalCount, setTotalCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopupMessage, setButtonPopupMessage] = useState(false);

  const likeThisReview = (reviewId) => {
    if (userToken) {
      dispatch(createLikeReview(reviewId, userToken)).then(() => {
        setLiked(true);
        setTotalCount(totalCount + 1);
      });
    } else {
      /*  alert("Please enter the general rating");  */
    }
  };

  const deleteThisReview = (reviewId) => {
    if (userToken) {
      dispatch(deleteLikeReview(reviewId, userToken)).then(() => {
        setLiked(false);
        setTotalCount(totalCount - 1);

        // The second function will be executed after the first one completes
      });
    } else {
      /*  alert("Please enter the general rating");  */
    }
  };

  async function getLikeCountFromReview(reviewId) {
    let totalLikeCount = await axios.get(
      process.env.REACT_APP_API_ADDRESS + `likeReviews/count/likes/${reviewId}`
    );

    const totalLikeReviewCount = totalLikeCount.data;

    setTotalCount(totalLikeReviewCount);
  }

  async function checkLikeFromReview(reviewId) {
    if (userToken) {
      let checkLike = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `likeReviews/check/like/${reviewId}/token/${userToken}`
      );

      const checkLikeData = checkLike.data;
      if (checkLikeData) {
        setLiked(true);
      }
    }
  }

  useEffect(() => {
    getLikeCountFromReview(reviewId);
  }, []);

  useEffect(() => {
    checkLikeFromReview(reviewId);
  }, []);

  return (
    <div>
      {liked && userToken ? (
        <div
          className="clickable"
          onClick={() => {
            deleteThisReview(reviewId);
          }}
        >
          <i className="fa-solid fa-heart" id="pinkFont"></i>
          <strong> {totalCount} </strong>
        </div>
      ) : uid && userIdReviewing == uid ? (
        <div>
          <PopUpMessage
            message="You cannot like your own review"
            trigger={buttonPopupMessage}
            setTrigger={setButtonPopupMessage}
          ></PopUpMessage>

          <div
            onClick={() => setButtonPopupMessage(true)}
            className="clickable"
          >
            <i className="fa-regular fa-heart"></i>
            <strong> {totalCount} </strong>
          </div>
        </div>
      ) : userToken ? (
        <div
          className="clickable"
          onClick={() => {
            likeThisReview(reviewId);
          }}
        >
          <i className="fa-regular fa-heart"></i>
          <strong> {totalCount} </strong>
        </div>
      ) : (
        <div>
          <PopUp trigger={buttonPopup} setTrigger={setButtonPopup}></PopUp>
          <div onClick={() => setButtonPopup(true)} className="clickable">
            <i className="fa-regular fa-heart"></i>
            <strong> {totalCount} </strong>
          </div>
        </div>
      )}
    </div>
  );
}

/*

      <button
        className="buttondelete"
        onClick={() => {
          likeThisReview(reviewId);
        }}
      ></button>
      <button
        onClick={() => {
          deleteThisReview(reviewId);
        }}
      ></button>

      */
