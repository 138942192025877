import React from "react";
import Rating from "./Rating";

export default function TopSimilarFavorites(props) {
  const similarChannelsList = props.similarChannelsList;

  return (
    <div className="row center">
      {similarChannelsList.map((product, index) => (
        <div
          className="thumbnails"
          title={
            product.description.split(/\s+/).slice(0, 30).join(" ") + "..."
          }
        >
          <a
            key={product._id}
            href={`/channel/${product.name
              .replaceAll(" ", "_")
              .replaceAll("-", "_")
              .toLowerCase()}-${product._id}`}
          >
            <div className="smallContainer">
              <img
                className="profileImage"
                src={product.profileImage}
                alt={product.name}
              />
              <img className="small" src={product.image} alt={product.name} />
            </div>
            <li></li>
            <a>{product.name}</a>
            <div className="alignHorizontal">
              <Rating
                mean={true}
                rating={product.rating}
                numReviews={product.numReviews}
              ></Rating>
            </div>
          </a>
          <div className="space"></div>
        </div>
      ))}
    </div>
  );
}
