import Axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_PLAYLIST2_REQUEST,
  USER_PLAYLIST2_SUCCESS,
  USER_PLAYLIST2_FAIL,
  USER_PLAYLIST_LIST_REQUEST,
  USER_PLAYLIST_LIST_SUCCESS,
  USER_PLAYLIST_LIST_FAIL,
  USER_REMOVE_PLAYLIST_REQUEST,
  USER_REMOVE_PLAYLIST_SUCCESS,
  USER_REMOVE_PLAYLIST_FAIL,
  USER_QUERY_LIST_REQUEST,
  USER_QUERY_LIST_SUCCESS,
  USER_QUERY_LIST_FAIL,
} from "../constants/userConstants";

export const register = (name, uid, token) => async (dispatch) => {
  /* dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password } });  */
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_ADDRESS + "users/register",
      {
        name,
        uid,
        token,
      }
    );
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_ADDRESS + "users/signin",
      { email, password }
    );
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  dispatch({ type: USER_SIGNOUT });
  document.location.href = "/signin";
};

export const updateUserProfile = (userInfo) => async (dispatch, getState) => {
  try {
    const { name, token, description, twitter, youtube } = userInfo;

    const requestData = {};
    if (name) requestData.name = name;
    if (token) requestData.token = token;
    if (description) requestData.description = description;
    if (twitter) requestData.twitter = twitter;
    if (youtube) requestData.youtube = youtube;

    const { data } = await Axios.put(
      process.env.REACT_APP_API_ADDRESS + `users/profile`,
      requestData
    );

    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    /* localStorage.setItem("userInfo", JSON.stringify(data)); */
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_ADDRESS + `users/${user._id}`,
      user,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + "users",
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.delete(
      process.env.REACT_APP_API_ADDRESS + `users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
  }
};

export const userAddPlaylist =
  (productId, token) => async (dispatch, getState) => {
    /*dispatch({ type: USER_PLAYLIST2_REQUEST });  */
    /*
    const {
      userSignin: { userInfo },
    } = getState();  */

    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_API_ADDRESS + `users/add/playlist2`,
        { productId, token }
      );

      /* a allait au dessus {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        } */
      dispatch({
        type: USER_PLAYLIST2_SUCCESS,
        payload: data.productId,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_PLAYLIST2_FAIL, payload: message });
    }
  };

export const userRemovePlaylist =
  (productId, token) => async (dispatch, getState) => {
    /* dispatch({ type: USER_REMOVE_PLAYLIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();  */
    try {
      const { data } = await Axios.delete(
        process.env.REACT_APP_API_ADDRESS + `users/remove/playlist2`,
        { data: { productId, token } }
      );

      dispatch({ type: USER_REMOVE_PLAYLIST_SUCCESS, payload: data.productId });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_REMOVE_PLAYLIST_FAIL, payload: message });
    }
  };

export const listPlaylist = (token) => async (dispatch, getState) => {
  /* dispatch({
    type: USER_PLAYLIST_LIST_REQUEST,
  });

  const {
    userSignin: { userInfo },
  } = getState();  */
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS +
        `users/list-playlist/playlist2/${token}`
    );

    dispatch({ type: USER_PLAYLIST_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_PLAYLIST_LIST_FAIL, payload: error.message });
  }
};

export const getDetailsUser = (token) => async (dispatch, getState) => {
  /*  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();   */
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `users/${token}`
    );

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

export const listUsersQuery = (query) => async (dispatch) => {
  dispatch({
    type: USER_QUERY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `users/query/userName/${query}`
    );
    dispatch({ type: USER_QUERY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_QUERY_LIST_FAIL, payload: error.message });
  }
};
