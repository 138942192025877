import React from "react";

export default function PopUpRatingNeeded(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <h1>
          Please enter a general rating before submitting your review{" "}
          <div className="space"></div>
          <i className="fa-solid fa-face-grin-beam-sweat"></i>{" "}
        </h1>
        <img
          className="picturePopUp"
          src="/images/rating.png"
          alt="Favoree Rating"
        ></img>
        <h3></h3>
        <p>Almost there!</p>
        <i
          onClick={() => props.setTrigger(false)}
          className="fa-solid fa-xmark"
        ></i>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
