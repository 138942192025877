export const PRODUCT_LIKE_REVIEW_CREATE_FAIL =
  "PRODUCT_LIKE_REVIEW_CREATE_FAIL";
export const PRODUCT_LIKE_REVIEW_CREATE_REQUEST =
  "PRODUCT_LIKE_REVIEW_CREATE_REQUEST";
export const PRODUCT_LIKE_REVIEW_CREATE_SUCCESS =
  "PRODUCT_LIKE_REVIEW_CREATE_SUCCESS";

export const PRODUCT_LIKE_REVIEW_DELETE_FAIL =
  "PRODUCT_LIKE_REVIEW_DELETE_FAIL";
export const PRODUCT_LIKE_REVIEW_DELETE_REQUEST =
  "PRODUCT_LIKE_REVIEW_DELETE_REQUEST";
export const PRODUCT_LIKE_REVIEW_DELETE_SUCCESS =
  "PRODUCT_LIKE_REVIEW_DELETE_SUCCESS";
