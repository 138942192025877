import React, { useEffect, useState } from "react";
import axios from "../../node_modules/axios/index";
import UserPicture from "../components/UserPicture";
import LoadingBox from "./LoadingBox";

const PopUpFollowers = ({
  setPopUpFollow,
  followList,
  title,
  popUpFollow,
  userAlreadyFollow,
}) => {
  const [userList, setUserList] = useState("");
  const [loadingList, setLoadingList] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingList(true);
      const userlist = followList.map((x) => x.uid);
      const data = {
        userlist,
      };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS + "users/userlist",
          data
        );
        setUserList(response.data);
        setLoadingList(false);
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );
        throw error; // Rethrow or handle error as needed
      }
    };
    if (followList && popUpFollow) {
      fetchData();
    }
  }, [userAlreadyFollow]);

  return (
    <div className="popup">
      <div
        className="popup-inner"
        style={{
          "max-width": "50rem",
        }}
      >
        <div>
          <h2 style={{ "margin-bottom": "0rem" }}>{title}</h2>
          <div className="space"></div>
          <div
            style={{
              "max-height": "60vh",
              display: "flex",
              "z-index": "1000",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "-moz-scrollbars-vertical",
                "overflow-y": "scroll",
                "max-height": "80%",
                width: "100%",
              }}
            >
              {loadingList ? (
                <div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <LoadingBox></LoadingBox>
                </div>
              ) : userList?.length > 0 ? (
                userList.map((user) => (
                  <a
                    className="alignHorizontal"
                    style={{ "margin-top": "3.5rem" }}
                    href={`/user/${user._id}`}
                    key={user.uid}
                  >
                    <UserPicture
                      uid={user.uid}
                      imageSize="avatarPictureSmall"
                    ></UserPicture>
                    <div
                      style={{
                        "text-align": "left",
                        margin: "auto 2rem auto 2rem",
                      }}
                    >
                      <h3>{user.name}</h3>
                      {user.description && (
                        <p
                          style={{
                            "text-align": "left",
                            "margin-bottom": "0rem",
                            "font-weight": "300",
                          }}
                        >
                          {user.description}
                        </p>
                      )}
                    </div>

                    <button
                      className="giveReview"
                      style={{
                        height: "3rem",
                        "margin-left": "auto",
                        "margin-right": "0rem",
                        "margin-top": "auto",
                        "margin-bottom": "auto",
                      }}
                    >
                      Profile
                    </button>
                  </a>
                ))
              ) : (
                <div style={{ "margin-top": "2rem" }}>
                  <p>No user for now 👻</p>
                </div>
              )}

              {userList?.length > 7 && (
                <div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                </div>
              )}
            </div>
          </div>
          {userList?.length > 7 && (
            <div
              className="fade-out"
              style={{
                position: "absolute",
                bottom: "4rem",
                left: 0,
                right: 0,
                height: "12rem",
                background:
                  "linear-gradient(to bottom, rgba(22,46,60,0), rgba(22,46,60,1))",
                pointerEvents: "none", // Allows clicks to pass through
                "border-radius": "12px",
              }}
            ></div>
          )}
          <div className="space"></div>
        </div>
        <i
          onClick={() => {
            setPopUpFollow(false);
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
    </div>
  );
};

export default PopUpFollowers;
