import React, { useEffect, useState } from "react";
import axios from "../../node_modules/axios/index";
import LoadingBox from "../components/LoadingBox";

export default function FollowButton(props) {
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [userAlreadyFollow, setUserAlreadyFollow] = useState(false);

  const token = props.token;
  const followedUid = props.followedUid;
  const followedId = props.followedId;
  const followerId = props.followerId;

  useEffect(() => {
    if (props.followingList.includes(followedUid)) {
      setUserAlreadyFollow(true);
    }
  }, []);

  const followTheUser = async () => {
    setLoadingFollow(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ADDRESS + `userfollows/addFollow`,
        { token, followedUid, followedId, followerId }
      );
      setUserAlreadyFollow(true);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching creator lists:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoadingFollow(false);
    }
  };

  const unfollowTheUser = async () => {
    setLoadingFollow(true);

    try {
      const response = await axios.delete(
        process.env.REACT_APP_API_ADDRESS + `userfollows/unfollow`,
        { data: { token, followedUid } }
      );
      setUserAlreadyFollow(false);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching creator lists:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoadingFollow(false);
    }
  };

  return (
    <div>
      {token && (
        <div
          style={{
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          }}
        >
          {loadingFollow ? (
            <div
              style={{
                height: "3.8rem",
              }}
            >
              <LoadingBox></LoadingBox>
            </div>
          ) : !userAlreadyFollow ? (
            <button
              onClick={() => {
                if (token) {
                  followTheUser();
                }
              }}
              style={{
                "background-color": "#0693e3",
              }}
              id="hoverOpacity"
            >
              <i class="fa-solid fa-user-plus"></i>&nbsp; Follow
            </button>
          ) : (
            <button
              onClick={() => {
                unfollowTheUser();
              }}
              style={{
                "background-color": "#4c5866",
              }}
              id="hoverOpacity"
            >
              <i class="fa-solid fa-user-minus"></i> Unfollow
            </button>
          )}
        </div>
      )}
    </div>
  );
}
