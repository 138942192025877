import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  productCategoryListReducer,
  productCountryListReducer,
  productAllTopicsListReducer,
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  productReviewCreateReducer,
  productUpdateReducer,
  productMoodListReducer,
  productReviewDeleteReducer,
  productPlatformListReducer,
  productContextListReducer,
  productLevelListReducer,
  productTagListReducer,
  productSubTopicListReducer,
  productNamesListQueryReducer,
  productListQueryReducer,
  videoListQueryReducer,
  /* playlistProductListReducer,  */
} from "./reducers/productReducers.js";
import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userRegisterReducer,
  userSigninReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
  userAddPlaylistReducer,
  userGetPlaylistReducer,
  userRemovePlaylistReducer,
  userListQueryReducer,
} from "./reducers/userReducers.js";

import { sendContributionReducer } from "./reducers/contributionReducers.js";
import { reviewsFromProgramReducer } from "./reducers/reviewReducers.js";
import {
  likeReviewCreateReducer,
  likeReviewDeleteReducer,
} from "./reducers/likeReviewReducers.js";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
  /*
  playlist: {
    playlistItems: localStorage.getItem("playlistItems")
      ? JSON.parse(localStorage.getItem("playlistItems"))
      : [],
    shippingAddress: localStorage.getItem("shippingAddress")
      ? JSON.parse(localStorage.getItem("shippingAddress"))
      : {},
  },  */
};

const reducer = combineReducers({
  productList: productListReducer,
  productListQuery: productListQueryReducer,
  productNamesListQuery: productNamesListQueryReducer,
  videoListQuery: videoListQueryReducer,
  userListQuery: userListQueryReducer,

  productDetails: productDetailsReducer,
  /* playlist: playlistReducer,  */
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdate: userUpdateReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  productCategoryList: productCategoryListReducer,
  productCountryList: productCountryListReducer,

  productMoodList: productMoodListReducer,
  productContextList: productContextListReducer,
  productLevelList: productLevelListReducer,
  productTagList: productTagListReducer,
  productPlatformList: productPlatformListReducer,
  /* productLanguageList: productLanguageListReducer,  */
  productAllTopicsList: productAllTopicsListReducer,
  productSubTopicList: productSubTopicListReducer,
  productReviewCreate: productReviewCreateReducer,
  likeReviewCreate: likeReviewCreateReducer,
  likeReviewDelete: likeReviewDeleteReducer,

  productReviewDelete: productReviewDeleteReducer,
  sendContribution: sendContributionReducer,
  userAddPlaylist: userAddPlaylistReducer,
  userRemovePlaylist: userRemovePlaylistReducer,
  listPlaylist: userGetPlaylistReducer,
  reviewsProgram: reviewsFromProgramReducer,
  /* playlistProductList: playlistProductListReducer,  */
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
