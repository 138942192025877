import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsUser } from "../actions/userActions";
import { useAuth } from "../AuthContext";
import { Link } from "../../node_modules/react-router-dom/index";
import { useMediaQuery } from "react-responsive";
import LoadingBox from "../components/LoadingBox";
import axios from "../../node_modules/axios/index";
import debounce from "lodash.debounce";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

export default function CreateListScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const [loadingCreateList, setLoadingCreateList] = useState(false);
  const [loadingSearchChannels, setLoadingSearchChannels] = useState(false);
  const [errorFindingChannel, setErrorFindingChannel] = useState("");

  const { currentUser } = useAuth();
  const [listId, setListId] = useState(props.match.params.id || "");
  const [listName, setListName] = useState("");
  const [description, setDescription] = useState("");
  const [privateList, setPrivateList] = useState(false);

  const [selectedCreators, setSelectedCreators] = useState([]);

  useEffect(() => {
    const token = currentUser.auth.currentUser.accessToken;
    const creatorListIds = [listId];

    const data = {
      creatorListIds, // Array of IDs you're interested in
      token, // Include the token if available
    };
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS +
            "creatorlists/getlisttomodify?" +
            `ids=${listId}`,
          data
        );

        const originalChannelList = response.data[0].list.map(
          ({ channelId, channelListNumber }) => ({
            channelId,
            channelListNumber,
          })
        );

        let channelListUpdated = [];

        for (let i = 0; i < originalChannelList.length; i++) {
          channelListUpdated.push({
            ...originalChannelList[i],
            ...response.data[0].channels.find(
              (itmInner) =>
                itmInner.channelId === originalChannelList[i].channelId
            ),
          });
        }

        setListName(response.data[0].listName);
        setDescription(response.data[0].description);
        setPrivateList(response.data[0].private);
        setSelectedCreators(channelListUpdated);

        return response.data; // Return the fetched data
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );
        throw error; // Rethrow or handle error as needed
      }
    };

    fetchData();
  }, []);

  const [message, setMessage] = useState("");
  const [errorListCreation, setErrorListCreation] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [youtubeChannelUrl, setYoutubeChannelUrl] = useState("");

  const [messageTimer, setMessageTimer] = useState(null);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, [currentUser]);

  async function submitHandler(e) {
    e.preventDefault();
    setMessage("");

    if (listName?.length < 1) {
      return setErrorListCreation("⛔ Your need to give a title to your list");
    }

    if (description?.length > 400) {
      return setErrorListCreation(
        "⛔ Your description cannot exceed 400 characters"
      );
    }

    if (listName?.length > 80) {
      return setErrorListCreation(
        "⛔ Your description cannot exceed 80 characters"
      );
    }

    if (selectedCreators?.length < 1) {
      return setErrorListCreation(
        "⛔ You need to select at least one channel to save the list"
      );
    }

    const promises = [];
    setLoadingCreateList(true);
    setErrorListCreation("");

    const token = currentUser.auth.currentUser.accessToken;

    const listInfo = {
      listId: listId,
      listName: listName,
      creatorList:
        selectedCreators.length > 0
          ? selectedCreators.map((creator, index) => {
              return {
                channelListNumber: index,
                channelName: creator.name,
                channelId: creator.channelId,
              };
            })
          : selectedCreators,
      description: description,
      private: privateList,
      token: token,
    };
    async function changeList(x) {
      const { data } = await axios.post(
        process.env.REACT_APP_API_ADDRESS + "creatorlists/addcreatorlist",
        listInfo
      );

      setListId(data._id);
      props.history.push("/create-list/" + data._id);
    }

    changeList();

    const updatedProfile = {};

    Promise.all(promises)
      .then(() => setMessage("List successfully updated ✅"))
      .catch((error) =>
        setErrorListCreation("Failed to update account. " + error)
      )
      .finally(() => {
        setLoadingCreateList(false);
      });
  }

  // Debounce function to limit requests to the backend while typing
  const debouncedSearch = debounce(async (query) => {
    setErrorFindingChannel("");

    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      setLoadingSearchChannels(true);
      const { data } = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products/autocomplete?term=${query}`
      );
      setSuggestions(data);
      setLoadingSearchChannels(false);
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
      setSuggestions([]);
    }
  }, 300); // Wait 300ms after the last keystroke to make the request

  useEffect(() => {
    debouncedSearch(searchTerm);
    // Cleanup function to cancel the debounced call if the component unmounts
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const addCreator = (creator) => {
    const isCreatorAlreadySelected = selectedCreators.some(
      (selectedCreator) => selectedCreator.channelId === creator.channelId
    );

    if (!isCreatorAlreadySelected) {
      setSelectedCreators((prevCreators) => [...prevCreators, creator]);
    }

    setSearchTerm(""); // Clear search term
    setSuggestions([]); // Clear current suggestions
    setMessage("");
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(selectedCreators);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedCreators(items);
    setMessage("");
  };

  /*
  const addYoutubeChannel = async (e) => {
    setErrorFindingChannel("");
    e.preventDefault();
    try {
      
      const { data } = await axios.post(
        process.env.REACT_APP_API_ADDRESS + "products/add-youtube-channel",
        {
          urlQuery: youtubeChannelUrl,
          token: currentUser.auth.currentUser.accessToken,
        }
      );

      const creator = { _id: youtubeChannelUrl, name: data.name }; // Creating a product object
      addCreator({
        name: data.name,
        channelId: data.channelId,
        profileImage: data.profileImage,
      }); // Reusing the addProduct function
      setYoutubeChannelUrl(""); // Clear the input field
    } catch (error) {
      console.error("Error adding YouTube channel:", error);
      // Handle error (e.g., show an error message)
      setErrorFindingChannel(
        "⛔ Channel not found, please add the handle or link of the channel"
      );
    }
  };

  */

  useEffect(() => {
    // Clear existing timer
    if (messageTimer) {
      clearTimeout(messageTimer);
    }

    if (message) {
      // Set a new timer if there is a message
      const timer = setTimeout(() => {
        setMessage("");
      }, 4000); // 4000 milliseconds = 4 seconds

      // Save the timer ID so it can be cleared if necessary
      setMessageTimer(timer);
    }

    // Cleanup function to clear the timer when the component unmounts or if message changes
    return () => {
      if (messageTimer) {
        clearTimeout(messageTimer);
      }
    };
  }, [message]);

  // Function to mark the list as removed
  const removeListHandler = async () => {
    const token = currentUser.auth.currentUser.accessToken;

    const listInfo = {
      listId: listId,
      listName: listName,
      creatorList:
        selectedCreators.length > 0
          ? selectedCreators.map((creator, index) => {
              return {
                channelListNumber: index,
                channelName: creator.name,
                channelId: creator.channelId,
              };
            })
          : selectedCreators,
      description: description,
      private: privateList,
      token: token,
      isRemoved: true,
    };

    // Pop-up confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this list?"
    );

    if (isConfirmed) {
      // User clicked OK
      try {
        // Assuming the backend endpoint to update the list's status is like this
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS + "creatorlists/addcreatorlist",
          listInfo
        );

        // Optionally, update local state or redirect the user
        console.log("List marked as removed", response.data);
        // For example, redirect to the lists page
        props.history.push("/lists");
      } catch (error) {
        console.error(
          "Error removing list:",
          error.response ? error.response.data : error.message
        );
        // Optionally, show an error message to the user
      }
    } else {
      // User clicked Cancel, do nothing
      console.log("List removal canceled");
    }
  };

  const isMobile = !useMediaQuery({
    query: "(min-width: 820px)",
  });

  return (
    <div className="mainElement">
      <div className="createListPage">
        <form className="form" onSubmit={submitHandler} id="settings">
          <div className="row top">
            <h2 style={{ width: "100%", "margin-bottom": "5rem" }}>
              Create a channel list{" "}
            </h2>{" "}
            <div className="columnForm">
              <div>
                <label htmlFor="listName"> Name of the list</label>
                <input
                  id="listName"
                  type="listName"
                  placeholder={
                    user && listName ? listName : "Give a name to your list!"
                  }
                  value={user && listName ? listName : ""}
                  onChange={(e) => setListName(e.target.value)}
                  style={{
                    width: "100%",
                    "max-width": "35rem",
                  }}
                ></input>
              </div>
              <div className="space"></div>

              <div>
                <label htmlFor="description"> List description </label>
                <textarea
                  id="description"
                  type="description"
                  placeholder={
                    description
                      ? description
                      : "Add a small description, emojis accepted 🔥"
                  }
                  value={description ? description : ""}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{
                    width: "100%",
                    "max-width": "35rem",
                  }}
                ></textarea>
              </div>
              <div className="space"></div>

              <div>
                <label htmlFor="private">Who can view</label>
                <div className="alignHorizontal">
                  <label className="switch">
                    <input type="checkbox" checked={privateList}></input>
                    <span
                      className="slider"
                      onClick={() => setPrivateList(!privateList)}
                    ></span>
                  </label>
                  <div className="space"></div>
                  <text>
                    {privateList
                      ? "You - Private list"
                      : "Anyone - Public list"}
                  </text>
                </div>
              </div>
              {!isMobile && (
                <div>
                  <div className="space"></div>
                  <div className="space"></div>

                  <div>
                    <label />
                    <button
                      disable={loadingCreateList}
                      className="primary"
                      type="submit"
                      style={{
                        width: "12rem",
                      }}
                    >
                      Save list
                    </button>
                    <button
                      type="button"
                      onClick={removeListHandler}
                      className="removeListButton buttondelete"
                      style={{
                        "margin-left": "2rem",
                        "padding-right": "2rem",
                        "padding-left": "2rem",
                      }}
                    >
                      Remove List
                    </button>
                    <div className="space"></div>

                    {errorListCreation && (
                      <text className="error">{errorListCreation}</text>
                    )}
                    {message && (
                      <div>
                        {" "}
                        <text className="success">{message}</text>
                      </div>
                    )}
                  </div>
                  <div className="space"></div>

                  <div>
                    <Link to="/lists">
                      <i class="fa-solid fa-arrow-left"></i> Back to channel
                      lists{" "}
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="columnForm">
              <div>
                <label htmlFor="channelList">Add channels</label>

                <input
                  type="text"
                  placeholder="Search for a channel 🔎"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ "border-radius": "20rem", "padding-left": "2rem" }}
                />
                {searchTerm ? (
                  <div className="channelSuggestions">
                    {errorFindingChannel && (
                      <text className="error">{errorFindingChannel}</text>
                    )}
                    <ul>
                      {loadingSearchChannels && (
                        <div>
                          <div className="space"></div>
                          <LoadingBox></LoadingBox>
                          <div className="space"></div>
                        </div>
                      )}
                      {suggestions.map((suggestion, index) => (
                        <li
                          className="alignHorizontal"
                          key={index}
                          onClick={() => addCreator(suggestion)}
                        >
                          <img
                            className="smallRound"
                            src={suggestion.profileImage}
                          ></img>
                          <div className="space"></div>
                          <strong>{suggestion.name}</strong>
                        </li> // Display the name of each suggestion
                      ))}
                    </ul>
                    <div className="space"></div>
                    <div
                      className="alignHorizontal"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <text
                        style={{
                          "margin-right": "2rem",
                          display: "inline-block",
                        }}
                      >
                        Can't find your channel?
                      </text>
                      <div
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <a
                          href="/add-channel"
                          target="_blank"
                          class="buttonLightGrey"
                          style={{
                            padding: "1rem",
                            display: "inline-block",
                          }}
                        >
                          Add new channel
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div className="space"></div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="selectedCreators">
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="channelItemList"
                    >
                      {selectedCreators.length < 1 && (
                        <div
                          style={{ color: "black" }}
                          className="alignVertical"
                        >
                          <div className="space"></div>
                          <strong>Your list is empty</strong>
                          <text>Add channels using the search field above</text>
                          <div className="space"></div>
                        </div>
                      )}

                      {selectedCreators.map((creator, index) => (
                        <Draggable
                          key={creator.channelId}
                          draggableId={String(creator.channelId)}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              className="alignHorizontal"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <i class="fa-solid fa-bars orderNumber"></i>
                              <text
                                className="orderNumber"
                                style={{
                                  "min-width": "5rem",
                                }}
                              >
                                {index + 1}
                              </text>
                              <img
                                className="smallRound"
                                src={creator.profileImage}
                              ></img>
                              <div className="space"></div>
                              <text>{creator.name}</text>{" "}
                              <i
                                class="fa-solid fa-xmark"
                                onClick={() =>
                                  setSelectedCreators(
                                    selectedCreators.filter(
                                      (selectedCreator) =>
                                        selectedCreator.channelId !==
                                        creator.channelId
                                    )
                                  )
                                }
                              ></i>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="space"></div>
              {isMobile && (
                <div>
                  <div>
                    <label />
                    <button
                      disable={loadingCreateList}
                      className="primary"
                      type="submit"
                      style={{
                        width: "12rem",
                      }}
                    >
                      Save list
                    </button>
                    <button
                      type="button"
                      onClick={removeListHandler}
                      className="removeListButton buttondelete"
                      style={{
                        "margin-left": "2rem",
                        "padding-right": "2rem",
                        "padding-left": "2rem",
                      }}
                    >
                      Remove List
                    </button>
                    <div className="space"></div>

                    {errorListCreation && (
                      <text className="error">{errorListCreation}</text>
                    )}
                    {message && (
                      <div>
                        {" "}
                        <text className="success">{message}</text>
                      </div>
                    )}
                  </div>
                  <div className="space"></div>

                  <div>
                    <Link to="/lists">
                      <i class="fa-solid fa-arrow-left"></i> Back to channel
                      lists
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
