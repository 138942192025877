export const moodLogos = [
  {
    name: "Informative",
    emoticonClass: "fa-solid fa-newspaper",
  },
  {
    name: "Relaxing",
    emoticonClass: "fa-solid fa-mug-hot",
  },
  {
    name: "Entertaining",
    emoticonClass: "fa-regular fa-face-smile-beam",
  },
  {
    name: "Fun",
    emoticonClass: "fa-solid fa-face-grin-squint-tears",
  },
  {
    name: "Funny",
    emoticonClass: "fa-solid fa-face-grin-squint-tears",
  },
  {
    name: "Instructional",
    emoticonClass: "fa-solid fa-graduation-cap",
  },
  {
    name: "Sad",
    emoticonClass: "fa-regular fa-face-sad-tear",
  },
  {
    name: "Stupid",
    emoticonClass: "fa-regular fa-face-rolling-eyes",
  },
  {
    name: "Inspiring",
    emoticonClass: "fa-regular fa-lightbulb",
  },
  {
    name: "Serious",
    emoticonClass: "fa-regular fa-face-meh",
  },
  {
    name: "Concise",
    emoticonClass: "fa-solid fa-forward",
  },
  {
    name: "Lengthy",
    emoticonClass: "fa-regular fa-hourglass-half",
  },
  {
    name: "Trustworthy",
    emoticonClass: "fa-regular fa-circle-check",
  },
  {
    name: "Unreliable",
    emoticonClass: "fa-solid fa-triangle-exclamation",
  },
  {
    name: "Kid-friendly",
    emoticonClass: "fa-solid fa-children",
  },
  {
    name: "Uplifting",
    emoticonClass: "fa-regular fa-face-laugh-beam",
  },
  {
    name: "Complex",
    emoticonClass: "fa-solid fa-brain",
  },
];
