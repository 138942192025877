import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "../../node_modules/axios/index";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import { useAuth } from "../AuthContext";
import Reviews from "../components/Reviews";
import FollowButton from "../components/FollowButton";
import { useSelector } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import UserPicture from "../components/UserPicture";

export default function SearchScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [reviewList, setReviewList] = useState("");
  const [toggleAllReviews, setToggleAllReviews] = useState("latestReviews");
  const [topReviewersList, setTopReviewersList] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [followingList, setFollowingList] = useState("");

  const { currentUser } = useAuth();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const isMobile = !useMediaQuery({
    query: "(min-width: 952px)",
  });

  useEffect(() => {
    async function getTopReviewers(x) {
      setLoadingData(true);

      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `reviews/top-reviewers/`
      );
      setTopReviewersList(response.data);
      setLoadingData(false);
    }
    if (toggleAllReviews == "topContributor") {
      getTopReviewers();
    }

    async function getReviews(x) {
      setLoadingData(true);

      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `reviews/getreviews/id/all/user/all/order/latest/type/all/moderation/true`
      );
      const reviewList = response.data;
      setReviewList(reviewList);
      setLoadingData(false);
    }
    if (toggleAllReviews == "latestReviews") {
      getReviews();
    }

    async function getLikedReviews(x) {
      setLoadingData(true);

      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `reviews/getreviews/id/all/user/all/order/mostliked/type/all/moderation/true`
      );
      const reviewList = response.data;
      setReviewList(reviewList);
      setLoadingData(false);
    }

    if (toggleAllReviews == "mostLikedReviews") {
      getLikedReviews();
    }

    async function getAllRatings(x) {
      setLoadingData(true);

      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `reviews/getreviews/id/all/user/all/order/latest/type/ratings/moderation/true`
      );
      const reviewList = response.data;
      setReviewList(reviewList);
      setLoadingData(false);
    }

    if (toggleAllReviews == "allRatings") {
      getAllRatings();
    }

    async function ratingsFromFollowed() {
      setLoadingData(true);

      try {
        // Fetch the list of users you're following
        const allFollowing = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}userfollows/followinglist/uid/${currentUser.uid}`
        );

        const listFollowing = allFollowing.data;

        // Extract user IDs and update state if necessary
        const followingIds = listFollowing.map((item) => item.uid);
        setFollowingList(followingIds);

        if (followingIds?.length > 0) {
          // Fetch reviews from the users you're following
          const response = await axios.post(
            `${process.env.REACT_APP_API_ADDRESS}reviews/reviewfromusers`,
            { followingList: followingIds }
          );

          const reviewList = response.data;

          setReviewList(reviewList);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoadingData(false);
      }
    }

    if (
      (toggleAllReviews == "followedUsers" ||
        toggleAllReviews == "topContributor") &&
      currentUser
    ) {
      ratingsFromFollowed();
    }
  }, [toggleAllReviews]);

  return (
    <div className="profileUser">
      <Helmet>
        <title>YouTube Channel Reviews and Ratings | Favoree</title>
        <meta
          name="description"
          content="Read the best and latest reviews about YouTube channels"
        ></meta>

        <link
          rel="canonical"
          href="https://www.favoree.io/latest-reviews"
        ></link>
      </Helmet>
      <div className="space"></div>
      <div className="space"></div>

      <div className="mainElement" id="topReviewers">
        <div className="alignVertical" id="toRight">
          {" "}
          <h1 style={{ "text-align": "center" }}>YouTube channel reviews</h1>
          {reviewList && (
            <div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="filterSlider">
                <ul class="ulFilter">
                  {reviewList.length > 9 && (
                    <button
                      className="sortVideos"
                      id={
                        toggleAllReviews == "latestReviews"
                          ? "sortVideosOn"
                          : "other"
                      }
                      onClick={() => setToggleAllReviews("latestReviews")}
                    >
                      <p>
                        <i class="fa-solid fa-comments"></i>Latest reviews{" "}
                      </p>
                    </button>
                  )}
                  {reviewList.length > 9 && (
                    <button
                      className="sortVideos"
                      id={
                        toggleAllReviews == "mostLikedReviews"
                          ? "sortVideosOn"
                          : "other"
                      }
                      onClick={() => setToggleAllReviews("mostLikedReviews")}
                    >
                      <p>
                        <i class="fa-solid fa-heart"></i> Most liked reviews{" "}
                      </p>
                    </button>
                  )}
                  <button
                    className="sortVideos"
                    id={
                      toggleAllReviews == "topContributor"
                        ? "sortVideosOn"
                        : "other"
                    }
                    onClick={() => setToggleAllReviews("topContributor")}
                  >
                    <p>
                      <i class="fa-solid fa-medal"></i> Top contributors{" "}
                    </p>
                  </button>
                  {reviewList.length > 9 && (
                    <button
                      className="sortVideos"
                      id={
                        toggleAllReviews == "allRatings"
                          ? "sortVideosOn"
                          : "other"
                      }
                      onClick={() => setToggleAllReviews("allRatings")}
                    >
                      <p>
                        <i class="fa-solid fa-star"></i>All ratings{" "}
                      </p>
                    </button>
                  )}
                  <button
                    className="sortVideos"
                    id={
                      toggleAllReviews == "followedUsers"
                        ? "sortVideosOn"
                        : "other"
                    }
                    onClick={() => setToggleAllReviews("followedUsers")}
                  >
                    <p>
                      <i class="fa-solid fa-user-group"></i> Followed users{" "}
                    </p>
                  </button>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>

        <div className="row top" id="coverReviews">
          {loadingData ? (
            <div>
              <LoadingBox></LoadingBox>
            </div>
          ) : toggleAllReviews == "followedUsers" &&
            !followingList?.length > 0 ? (
            <div id="leftAlign">
              <div className="space"></div>

              <text>
                👋 You need to follow users to see their latest reviews here.
              </text>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
            </div>
          ) : reviewList && toggleAllReviews !== "topContributor" ? (
            <Reviews
              reviewList={reviewList.filter((review) =>
                toggleAllReviews == "latestReviews"
                  ? review.comment.length >=
                    (toggleAllReviews == "latestReviews" ? 1 : 0)
                  : review
              )}
              currentUser={currentUser}
              reviewRemoval={true}
            ></Reviews>
          ) : toggleAllReviews == "topContributor" ? (
            <div className="row center">
              {topReviewersList &&
                topReviewersList.length > 0 &&
                topReviewersList.map((reviewer, index) => (
                  <li
                    className="review"
                    key={reviewer._id}
                    id={
                      "contributor" +
                      (index == 0
                        ? "1"
                        : index == 1
                        ? "2"
                        : index == 2
                        ? "3"
                        : "")
                    }
                  >
                    <div className="topReviewer">
                      <div className="rankingTopContributor">
                        {index == 0 ? (
                          <img
                            className="medal"
                            src="/images/gold_medal.png"
                            alt="Number one"
                          ></img>
                        ) : index == 1 ? (
                          <img
                            className="medal"
                            src="/images/silver_medal.png"
                            alt="Number three"
                          ></img>
                        ) : index == 2 ? (
                          <img
                            className="medal"
                            src="/images/bronze_medal.png"
                            alt="Number three"
                          ></img>
                        ) : (
                          <strong>{index + 1}</strong>
                        )}
                      </div>
                      <a
                        href={`/user/${reviewer._id}`}
                        className="avatarPictureTopContributor"
                      >
                        <UserPicture
                          uid={reviewer?.uid}
                          imageSize="avatarPictureSmall"
                        ></UserPicture>{" "}
                      </a>

                      <a
                        className="alignVertical"
                        href={`/user/${reviewer._id}`}
                      >
                        <strong>{reviewer.userName} </strong>
                        <div className="alignHorizontal">
                          {reviewer.twitter && (
                            <a
                              target="_blank"
                              href={"https://twitter.com/" + reviewer.twitter}
                            >
                              {" "}
                              <i class="fa-brands fa-twitter"></i>{" "}
                            </a>
                          )}
                          {reviewer.twitter && reviewer.youtube ? (
                            <div className="space"></div>
                          ) : (
                            <div> </div>
                          )}

                          {reviewer.youtube && (
                            <a
                              target="_blank"
                              href={
                                "https://www.youtube.com/" + reviewer.youtube
                              }
                            >
                              <i class="fa-brands fa-youtube"></i>
                            </a>
                          )}
                        </div>
                      </a>

                      <text>{reviewer.nbReviewsWithComments} reviews</text>
                      <text>{reviewer.nbReviews} ratings</text>
                      <text>
                        {Math.round(
                          (reviewer.totalRatings / reviewer.nbReviews) * 10
                        ) / 10}
                        /5 average rating
                      </text>

                      {currentUser && followingList && reviewer && (
                        <div
                          style={
                            isMobile
                              ? {
                                  width: "100%",
                                  "margin-top": "3rem",
                                }
                              : {}
                          }
                        >
                          <FollowButton
                            followingList={followingList}
                            followedUid={reviewer.uid}
                            followedId={reviewer._id}
                            followerId={user.userId}
                            token={currentUser.auth.currentUser.accessToken}
                          ></FollowButton>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </div>
          ) : (
            <div> </div>
          )}
        </div>
      </div>

      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h4>Can't find your favorite channel?</h4>
            <div className="space"></div>

            <a className="buttonLightGrey" id="darkBlue" href="/add-channel">
              {" "}
              Add new channel
            </a>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="/images/addChannel.png"
                alt="add a Channel"
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
}
