import React from "react";
import { Link } from "react-router-dom";

export default function PopUp(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <h2>To enjoy the whole Favoree experience, please sign in</h2>

        <div className="alignVertical">
          <img
            className="imageSubmitted"
            src="/images/addChannel.png"
            alt="Favoree Review"
          ></img>
          <div className="space"></div>

          <strong>⭐ Rate and review channels</strong>
          <strong>🆕 Add new channels</strong>
          <strong>🗂️ Create channel lists</strong>
          <strong>💖 Add favorites</strong>
        </div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>

        <p>
          Join us, we are nice! Please <Link to="/signin">log in</Link> or{" "}
          <Link to="/register">sign up</Link>
        </p>
        <i
          onClick={() => {
            props.setTrigger(false);
          }}
          className="fa-solid fa-xmark"
        ></i>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
