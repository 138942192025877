/* import { bindActionCreators } from "redux";  */

const {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_RESET,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_LANGUAGE_LIST_REQUEST,
  PRODUCT_LANGUAGE_LIST_SUCCESS,
  PRODUCT_LANGUAGE_LIST_FAIL,
  PRODUCT_ALLTOPICS_LIST_REQUEST,
  PRODUCT_ALLTOPICS_LIST_SUCCESS,
  PRODUCT_ALLTOPICS_LIST_FAIL,
  PRODUCT_SUBTOPIC_LIST_REQUEST,
  PRODUCT_SUBTOPIC_LIST_SUCCESS,
  PRODUCT_SUBTOPIC_LIST_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
  PRODUCT_REVIEW_CREATE_RESET,
  PRODUCT_ALLTOPIC_CREATE_REQUEST,
  PRODUCT_ALLTOPIC_CREATE_SUCCESS,
  PRODUCT_ALLTOPIC_CREATE_FAIL,
  PRODUCT_ALLTOPIC_CREATE_RESET,
  PRODUCT_MOOD_LIST_REQUEST,
  PRODUCT_MOOD_LIST_SUCCESS,
  PRODUCT_MOOD_LIST_FAIL,
  PRODUCT_CONTEXT_LIST_REQUEST,
  PRODUCT_CONTEXT_LIST_SUCCESS,
  PRODUCT_CONTEXT_LIST_FAIL,
  PRODUCT_LEVEL_LIST_REQUEST,
  PRODUCT_LEVEL_LIST_SUCCESS,
  PRODUCT_LEVEL_LIST_FAIL,
  PRODUCT_TAG_LIST_REQUEST,
  PRODUCT_TAG_LIST_SUCCESS,
  PRODUCT_TAG_LIST_FAIL,
  PRODUCT_REVIEW_DELETE_REQUEST,
  PRODUCT_REVIEW_DELETE_SUCCESS,
  PRODUCT_REVIEW_DELETE_FAIL,
  PRODUCT_REVIEW_DELETE_RESET,
  PRODUCT_PLATFORM_LIST_REQUEST,
  PRODUCT_PLATFORM_LIST_SUCCESS,
  PRODUCT_PLATFORM_LIST_FAIL,
  PLAYLIST_PRODUCT_LIST_REQUEST,
  PLAYLIST_PRODUCT_LIST_SUCCESS,
  PLAYLIST_PRODUCT_LIST_FAIL,
  PRODUCT_QUERY_LIST_REQUEST,
  PRODUCT_QUERY_LIST_SUCCESS,
  PRODUCT_QUERY_LIST_FAIL,
  VIDEO_QUERY_LIST_REQUEST,
  VIDEO_QUERY_LIST_SUCCESS,
  VIDEO_QUERY_LIST_FAIL,
  PRODUCT_COUNTRY_LIST_REQUEST,
  PRODUCT_COUNTRY_LIST_SUCCESS,
  PRODUCT_COUNTRY_LIST_FAIL,
  PRODUCT_NAME_QUERY_LIST_REQUEST,
  PRODUCT_NAME_QUERY_LIST_SUCCESS,
  PRODUCT_NAME_QUERY_LIST_FAIL,
} = require("../constants/productConstants");

export const productListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productNamesListQueryReducer = (
  state = { loadingNameQuery: true, productNamesQuery: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_NAME_QUERY_LIST_REQUEST:
      return { loadingNameQuery: true };
    case PRODUCT_NAME_QUERY_LIST_SUCCESS:
      return { loadingNameQuery: false, productNamesQuery: action.payload };
    case PRODUCT_NAME_QUERY_LIST_FAIL:
      return { loadingNameQuery: false, errorNameQuery: action.payload };
    default:
      return state;
  }
};

export const productListQueryReducer = (
  state = { loadingQuery: true, productsQuery: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_QUERY_LIST_REQUEST:
      return { loadingQuery: true };
    case PRODUCT_QUERY_LIST_SUCCESS:
      return { loadingQuery: false, productsQuery: action.payload };
    case PRODUCT_QUERY_LIST_FAIL:
      return { loadingQuery: false, errorQuery: action.payload };
    default:
      return state;
  }
};

export const videoListQueryReducer = (
  state = { loadingVideoQuery: true, videosQuery: [] },
  action
) => {
  switch (action.type) {
    case VIDEO_QUERY_LIST_REQUEST:
      return { loadingVideoQuery: true };
    case VIDEO_QUERY_LIST_SUCCESS:
      return { loadingVideoQuery: false, videosQuery: action.payload };
    case VIDEO_QUERY_LIST_FAIL:
      return { loadingVideoQuery: false, errorVideoQuery: action.payload };
    default:
      return state;
  }
};

export const productCategoryListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case PRODUCT_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCountryListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_COUNTRY_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_COUNTRY_LIST_SUCCESS:
      return { loading: false, countries: action.payload };
    case PRODUCT_COUNTRY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productLanguageListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LANGUAGE_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LANGUAGE_LIST_SUCCESS:
      return { loading: false, languages: action.payload };
    case PRODUCT_LANGUAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productMoodListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_MOOD_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_MOOD_LIST_SUCCESS:
      return { loading: false, rankingRatingMoodDefs: action.payload };
    case PRODUCT_MOOD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productContextListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CONTEXT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_CONTEXT_LIST_SUCCESS:
      return { loading: false, contexts: action.payload };
    case PRODUCT_CONTEXT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productLevelListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LEVEL_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LEVEL_LIST_SUCCESS:
      return { loading: false, levels: action.payload };
    case PRODUCT_LEVEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productTagListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_TAG_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_TAG_LIST_SUCCESS:
      return { loading: false, tags: action.payload };
    case PRODUCT_TAG_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productPlatformListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_PLATFORM_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_PLATFORM_LIST_SUCCESS:
      return { loading: false, platforms: action.payload };
    case PRODUCT_PLATFORM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSubTopicListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SUBTOPIC_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_SUBTOPIC_LIST_SUCCESS:
      return { loading: false, subtopics: action.payload };
    case PRODUCT_SUBTOPIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productAllTopicsListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_ALLTOPICS_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_ALLTOPICS_LIST_SUCCESS:
      return { loading: false, alltopicss: action.payload };
    case PRODUCT_ALLTOPICS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case PRODUCT_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productReviewDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_REVIEW_DELETE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case PRODUCT_REVIEW_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REVIEW_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productAllTopicCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ALLTOPIC_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_ALLTOPIC_CREATE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case PRODUCT_ALLTOPIC_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_ALLTOPIC_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
