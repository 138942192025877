import React, { useRef } from "react";
import Rating from "./Rating";

export default function TopSimilar(props) {
  const containerRef = useRef(null);

  const handleChevronClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  };
  return (
    <div>
      {props.productList && (
        <div>
          <div className="similarSlide">
            {props?.titleTop && (
              <h3>
                <i class="fa-solid fa-ranking-star"></i> &nbsp; {props.titleTop}
              </h3>
            )}
            <div className="channelsHP" ref={containerRef}>
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={handleChevronClick}
              ></i>
              {props.productList.map((product, index) => (
                <div
                  className="thumbnails"
                  title={
                    product.description.split(/\s+/).slice(0, 30).join(" ") +
                    "..."
                  }
                >
                  <a className="rankingSimilar">{index + 1}</a>
                  <a
                    key={product._id}
                    href={`/channel/${product.name
                      .replaceAll(" ", "_")
                      .replaceAll("-", "_")
                      .toLowerCase()}-${product._id}`}
                  >
                    <div className="smallContainer">
                      <img
                        loading="lazy"
                        className="profileImage"
                        src={product.profileImage}
                        alt={product.name}
                      />
                      <img
                        loading="lazy"
                        className="small"
                        src={product.image}
                        alt={product.name}
                      />
                    </div>
                    <li></li>
                    <a>{product.name}</a>
                    <div className="alignHorizontal">
                      <Rating
                        mean={true}
                        rating={product.rating}
                        numReviews={product.numReviews}
                      ></Rating>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="space"></div>
        </div>
      )}
    </div>
  );
}
