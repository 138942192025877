export const numReviewss = [
  {
    name: "All",
    min: 0,
    max: 0,
  },
  {
    name: "1 to 10",
    min: 1,
    max: 10,
  },
  {
    name: "10 to 100",
    min: 10,
    max: 100,
  },
];

export const ratings = [
  {
    name: "4 stars & up",
    rating: 4,
  },
  {
    name: "3 stars & up",
    rating: 3,
  },
  {
    name: "2 stars & up",
    rating: 2,
  },
  {
    name: "1 stars & up",
    rating: 1,
  },
];
