import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "../../node_modules/axios/index";
import BrowseVideosHP from "../components/BrowseVideosHP";
import MultiRangeSlider from "../components/Slider";
import LaunchVideo from "../components/LaunchVideo";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function SearchScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*
  const productSubTopicList = useSelector((state) => state.productSubTopicList);
  const {
    loading: loadingSubTopics,
    error: errorSubTopics,
    subtopics,
  } = productSubTopicList;  */

  const [selectedVideos, setSelectedVideos] = useState("");
  const [nbSelectedVideos, setNbSelectedVideos] = useState(0);

  /*
  const [channelsHPDoc, setChannelsHPDoc] = useState("");
  const [similarChannelWeek, setSimilarChannelWeek] = useState("");   */

  const [topic, setTopic] = useState(
    window.location.href.split("browse-videos/")[1]
      ? window.location.href.split("browse-videos/")[1].replaceAll("%20", " ")
      : "All"
  );

  const [latest, setLatest] = useState(true);
  const [popular, setPopular] = useState(false);
  const [loved, setLoved] = useState(false);
  const [hidden, setHidden] = useState(false);

  const [durationMin, setDurationMin] = useState(5);
  const [durationMax, setDurationMax] = useState(1400);

  const [videoPlayed, setVideoPlayed] = useState("");
  const [channelPlayed, setChannelPlayed] = useState("");

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    async function launchTopVideos(topic, functionTopass) {
      let responseVideos = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products/browsevideos/discovervideos/${topic}/${durationMin}/${durationMax}/${
            latest
              ? "latest"
              : popular
              ? "popular"
              : loved
              ? "loved"
              : hidden
              ? "hidden"
              : "loved"
          }`
      );
      functionTopass(responseVideos.data);
    }

    launchTopVideos(topic, setSelectedVideos);
  }, [topic, durationMax, durationMin, latest, loved, popular, hidden]);

  var parent = document.querySelector("#rangeSlider", ".level-filter");

  useEffect(() => {
    if (parent) {
      parent.addEventListener("change", (event) => {
        var rangeS = parent.querySelectorAll("input[type=range]");

        var slide1 = parseFloat(rangeS[0].value),
          slide2 = parseFloat(rangeS[1].value);

        if (slide1 > slide2) {
          [slide1, slide2] = [slide2, slide1];
        }

        if (slide2 > 39) {
          slide2 = 1400;
        }

        setDurationMax(slide2);
        setDurationMin(slide1);
        setNbSelectedVideos(0);
      });
    }
  }, [parent]);

  const latestFunction = () => {
    setLatest(true);
    setPopular(false);
    setLoved(false);
    setHidden(false);

    setNbSelectedVideos(0);
  };

  const lovedFunction = () => {
    setLatest(false);
    setPopular(false);
    setLoved(true);
    setHidden(false);

    setNbSelectedVideos(0);
  };

  const popularFunction = () => {
    setLatest(false);
    setPopular(true);
    setLoved(false);
    setHidden(false);

    setNbSelectedVideos(0);
  };

  const hiddenFunction = () => {
    setLatest(false);
    setPopular(false);
    setLoved(false);
    setHidden(true);

    setNbSelectedVideos(0);
  };

  const isMobile = !useMediaQuery({
    query: "(min-width: 1034px)",
  });

  const isMobile2 = !useMediaQuery({
    query: "(min-width: 600px)",
  });

  /*
  useEffect(() => {
    async function getChannels(x, setFunction) {
      let response2 = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `products/${x}`
      );
      const channelsDoc = [response2.data];
      setFunction(channelsDoc);
    }

    getChannels(
      "63acadf11f9e9229a0861a84_63741eb9afe389b1ac38ceb4_637f439fafe389b1ac38cf51_639e588f1f9e9229a0861a5c_637261afafe389b1ac38ce8a_63b56713e0f22c53255b73e5_62b6296e2cfb426e27572c89_637525d3afe389b1ac38cee5",
      setChannelsHPDoc
    );
    getChannels(
      "63b56713e0f22c53255b73e5_63cf1219e0f22c53255b7507_639e58901f9e9229a0861a60_63d06a74e0f22c53255b7530_63d06a75e0f22c53255b7532",
      setSimilarChannelWeek
    );
  }, []);  */

  useEffect(() => {
    if (topic !== "All")
      window.history.pushState({}, "browse-videos", `/browse-videos/${topic}`);
    else {
      window.history.pushState({}, "", `/browse-videos`);
    }
  }, [topic]);

  return (
    <div>
      <Helmet>
        <title>Best YouTube Videos | Favoree</title>
        <meta
          name="description"
          content="Search and watch YouTube videos from the best rated channels on Favoree. Sort by topic, date, popularity, duration and more"
        ></meta>
        <link
          rel="canonical"
          href="https://www.favoree.io/browse-videos"
        ></link>
      </Helmet>
      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h1>
              Discover quality videos you wouldn't have thought to search for
            </h1>
            <p>
              Watch YouTube videos from the best rated channels on Favoree, by
              topics
            </p>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="../images/browseVideosPlayer.png"
                alt="The best channel for every topic"
              ></img>
            </div>
          )}
        </div>
      </div>
      {!isMobile && <div className="space"></div>}
      <div className="centered">
        <div className="coverInside"></div>

        <div className="fiftyPercent" id="paddingTop">
          <div className="filterVideos">
            <button
              className={latest ? "browseSort sortOn" : "browseSort"}
              onClick={latestFunction}
            >
              <a>🕗 Latest releases</a>
            </button>
            <button
              className={popular ? "browseSort sortOn" : "browseSort"}
              onClick={popularFunction}
            >
              {isMobile ? (
                <a>&nbsp;⭐&nbsp; Most viewed</a>
              ) : (
                <a>⭐ Most viewed</a>
              )}
            </button>

            <button
              className={loved ? "browseSort sortOn" : "browseSort"}
              onClick={lovedFunction}
            >
              {isMobile ? (
                <a>&nbsp;&nbsp;💖&nbsp;&nbsp; Best rated</a>
              ) : (
                <a>💖 Best rated</a>
              )}
            </button>
            <button
              className={hidden ? "browseSort sortOn" : "browseSort"}
              onClick={hiddenFunction}
            >
              <a>💎 Hidden gems</a>
            </button>
          </div>
        </div>
        <div className="fiftyPercent" id="paddingTop">
          <div className="filter level-filter level-req">
            <div id="rangeSlider" className="range-slider">
              <div className="alignHorizontal">
                <a>Video duration</a>
                <div className="alignVertical">
                  <MultiRangeSlider
                    min={5}
                    max={40}
                    onChange={({ min, max }) => {
                      if (max > 39) {
                        max = 1400;
                      }
                      setDurationMax(max);
                      setDurationMin(min);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="backgroundTableHP">
          <table>
            <colgroup>
              {!isMobile2 && <col width="200"></col>}
              <col width="1100"></col>
            </colgroup>
            <tbody>
              <BrowseVideosHP
                topic={topic}
                setTopic={setTopic}
                key={selectedVideos._id}
                generalVideos={selectedVideos}
                setNbGeneralVideos={setNbSelectedVideos}
                nbGeneralVideos={nbSelectedVideos}
                setVideoPlayed={setVideoPlayed}
                videoPlayed={videoPlayed}
                setChannelPlayed={setChannelPlayed}
                channelPlayed={channelPlayed}
              ></BrowseVideosHP>
            </tbody>
          </table>
        </div>
        {videoPlayed && (
          <LaunchVideo
            videoPlayed={videoPlayed}
            setVideoPlayed={setVideoPlayed}
            setChannelPlayed={setChannelPlayed}
            channelPlayed={channelPlayed}
          ></LaunchVideo>
        )}
      </div>
      <div className="space"></div>
      <div className="space"></div> <div className="space"></div>{" "}
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
}
