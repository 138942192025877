import React, { useCallback, useEffect, useState, useRef } from "react";
/* import PropTypes from "prop-types";  */

export default function MultiRangeSlider({
  min,
  max,
  onChange,
  getFilterUrl,
  duration,
}) {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - 0) / 40) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
    /*window.location.href = url; */
  }, [minVal, maxVal, onChange]);

  return (
    <div className="container2">
      <input
        type="range"
        min={0}
        max={40}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb2 thumb--left2"
        style={{ zIndex: minVal > max - 100 && "5" }}
        step="5"
      ></input>

      <input
        type="range"
        min={0}
        max={40}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb2 thumb--right2"
        step="5"
      />
      <div className="slider2">
        <div className="slider__track2" />
        <div ref={range} className="slider__range2" />
        <a className="slider__left-value2">{minVal + " min"}</a>
        <a className="slider__right-value2">
          {maxVal + " min" + (maxVal > 39 ? "+" : "")}
        </a>
      </div>
    </div>
  );
}

/*
MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;  */

/*
    <Link
      to={getFilterUrl({
        duration: minVal + "-" + maxVal,
      })}
      className="bof"
    ></Link>  */
