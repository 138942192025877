import React from "react";

export default function PopUpReviewSubmitted(props) {
  return props.trigger ? (
    <div className="popup">
      <div
        className="popup-inner"
        style={{
          width: "300px",
        }}
      >
        <h1>Review submitted ✅</h1>
        <img
          className="imageSubmitted"
          src="/images/addChannel.png"
          alt="Favoree Review"
        ></img>
        <div className="space"></div>
        <strong>Thank you for helping Favoree grow ❤️‍🔥</strong>
        <i
          onClick={() => props.setTrigger(false)}
          className="fa-solid fa-xmark"
        ></i>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
