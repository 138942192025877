import React from "react";

export default function PopUpMessage(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner" id="smallPopUp">
        <i
          onClick={() => props.setTrigger(false)}
          className="fa-solid fa-xmark"
        ></i>
        {"   "}
        {props.type && props.type == "check" ? (
          "✅"
        ) : (
          <i class="fa-regular fa-circle-xmark"></i>
        )}
        {"   "} {props.message}
      </div>
    </div>
  ) : (
    ""
  );
}
