import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "../../node_modules/react-router-dom/index.js";
import { useAuth } from "../AuthContext.js";
import {
  getDetailsUser,
  listPlaylist,
  signout,
} from "../actions/userActions.js";

export default function LoginHeader() {
  const dispatch = useDispatch();

  const signoutHandler = () => {
    dispatch(signout());
  };
  const { currentUser, logout } = useAuth();
  const [errorLogin, setErrorLogin] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, [currentUser, dispatch]);

  async function handleLogout() {
    setErrorLogin("");
    try {
      await logout().then(signoutHandler);
    } catch {
      setErrorLogin("Failed to log out");
    }
  }

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(listPlaylist(currentUser.auth.currentUser.accessToken));
    }
  }, [dispatch]);

  return (
    <div>
      {currentUser ? (
        <div className="logUser">
          <a href="/favorites" onClick={() => refreshPage()}>
            <i className="fa-solid fa-heart"></i>
          </a>
          <div className="dropdown">
            <Link to="#">
              <i className="fa-solid fa-user"></i>{" "}
              <i className="fa fa-caret-down"></i>{" "}
            </Link>
            <ul className="dropdown-content">
              {user && (
                <li>
                  <Link to={`/user/${user.userId}`}> My profile</Link>
                </li>
              )}
              {user && (
                <li>
                  <Link to="/settings"> Settings </Link>
                </li>
              )}

              <li>
                <Link to="#signout" onClick={handleLogout}>
                  Sign Out
                </Link>
                {errorLogin && <text className="error">{errorLogin}</text>}
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <Link className="noHover" to="/register">
            <button className="signUpButton"> Sign up</button>
          </Link>
          <Link className="noHover" to="/signin">
            <button className="buttonLogin"> Log in</button>
          </Link>
        </div>
      )}
    </div>
  );
}
