import React, { useContext, useEffect, useState } from "react";
import { authFB, provider, storage } from "./firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();

  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return authFB.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return authFB.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return authFB.signOut();
  }

  function resetPassword(email) {
    return authFB.sendPasswordResetEmail(email);
  }

  /*var provider = new authFB.GoogleAuthProvider();*/

  function loginGmail() {
    return authFB.signInWithPopup(provider);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function uploadProfilePicture(file) {
    const storageRef = storage.ref(`profile_pictures/${currentUser.uid}`);
    try {
      // Asynchronously uploads the file to Firebase Storage
      const snapshot = await storageRef.put(file);

      // Retrieves the URL of the uploaded file
      const downloadURL = await snapshot.ref.getDownloadURL();

      // Updates the user's profile with the new photo URL
      await currentUser.updateProfile({ photoURL: downloadURL });

      return downloadURL; // Returns the download URL of the image
    } catch (error) {
      // Handle any errors here
      throw error;
    }
  }

  async function removeProfilePicture() {
    const storageRef = storage.ref(`profile_pictures/${currentUser.uid}`);
    try {
      // Asynchronously deletes the file from Firebase Storage
      await storageRef.delete();

      // Updates the user's profile to remove the photo URL
      await currentUser.updateProfile({ photoURL: null });

      // You might want to return a confirmation message or handle the absence of a profile picture in your UI
      return "Profile picture removed successfully.";
    } catch (error) {
      // Handle any errors here, such as the file not existing
      throw error;
    }
  }

  useEffect(() => {
    const unsubscribe = authFB.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    // Refresh the token every hour
    const refreshInterval = setInterval(async () => {
      if (currentUser) {
        const user = authFB.currentUser;
        setCurrentUser(user);
        setLoading(false);
        /* const token = await currentUser.getIdToken(true);  */
        // Perform any necessary actions with the refreshed token.
      }
    }, 60 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [currentUser]);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    loginGmail,
    uploadProfilePicture,
    removeProfilePicture,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
