import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moment from "../../node_modules/moment/moment";

export default function LaunchVideo(props) {
  const { videoPlayed, setVideoPlayed, setChannelPlayed, channelPlayed } =
    props;

  const isMobile = !useMediaQuery({
    query: "(min-width: 1034px)",
  });

  const smallMobile = !useMediaQuery({
    query: "(min-width: 385px)",
  });

  return (
    <div className="videoThumbnailsBrowseClicked">
      <i
        className="fa-solid fa-xmark"
        id="crossStopVideo"
        onClick={() => {
          setVideoPlayed("");
          setChannelPlayed("");
        }}
      ></i>
      <div className="alignVertical">
        <iframe
          width={smallMobile ? "300" : isMobile ? "380" : "440"}
          height={smallMobile ? "213" : isMobile ? "213" : "246"}
          src={
            "https://www.youtube.com/embed/" +
            videoPlayed.videoId +
            "?autoplay=1"
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
        ></iframe>
        <a
          href={"https://www.youtube.com/watch?v=" + videoPlayed.videoId}
          target="_blank"
        >
          {videoPlayed.title.split(/\s+/).slice(0, 15).join(" ")}
          {videoPlayed.title.match(/(\w+)/g) &&
          videoPlayed.title.match(/(\w+)/g).length > 15
            ? "..."
            : ""}
        </a>

        <Link
          to={`/channel/${channelPlayed[0]
            .replaceAll(" ", "_")
            .replaceAll("-", "_")
            .toLowerCase()}-${channelPlayed[1]}`}
        >
          <text>{channelPlayed[0]}</text> <text>-</text>{" "}
          <text>
            {videoPlayed.nbViews > 1000000
              ? Math.round(videoPlayed.nbViews / 100000) / 10 + "M"
              : videoPlayed.nbViews > 10000
              ? Math.round(videoPlayed.nbViews / 1000) + "K"
              : videoPlayed.nbViews > 1000
              ? Math.round(videoPlayed.nbViews / 100) / 10 + "K"
              : videoPlayed.nbViews}{" "}
            views
          </text>{" "}
          <text>-</text>{" "}
          <text>{moment(videoPlayed.publishDate).fromNow()}</text>
        </Link>
      </div>
    </div>
  );
}
