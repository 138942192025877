export const topicList = {
  All: <i class="fa-solid fa-border-all"></i>,
  "History and Geography": <i class="fa-solid fa-archway"></i>,
  "Cinema and TV": <i class="fa-solid fa-film"></i>,
  Games: <i class="fa-solid fa-gamepad"></i>,
  "Business and Economics": <i class="fa-solid fa-sack-dollar"></i>,
  Science: <i class="fa-solid fa-flask"></i>,
  "True Crime": <i class="fa-solid fa-person-falling-burst"></i>,
  Sport: <i class="fa-solid fa-futbol"></i>,
  "Fashion and Lifestyle": <i class="fa-solid fa-shirt"></i>,
  Travel: <i class="fa-solid fa-route"></i>,
  "Food and Cooking": <i class="fa-solid fa-kitchen-set"></i>,
  Music: <i class="fa-solid fa-music"></i>,
  Politics: <i class="fa-solid fa-landmark"></i>,
};

export const topicUrl = {
  "History and Geography":
    "/search/allTopics/History%20and%20Geography/subTopic/Archeology_Geography_History_War",
  "Cinema and TV": "/search/allTopics/Cinema%20and%20TV",
  Games:
    "/search/allTopics/Games/subTopic/Gaming_Consoles_Hardware_Retrogaming",
  "Business and Economics": "/search/allTopics/Business%20and%20Economics",
  Science: "/search/allTopics/Science",
  "True Crime":
    "/search/allTopics/Politics%20and%20Law/subTopic/Crimes%20and%20Scams",
  Sport: "/search/allTopics/Sport",
  "Fashion and Lifestyle":
    "/search/allTopics/Fashion%20and%20Lifestyle/subTopic/Clothing_Sneakers",
  Travel: "/search/allTopics/History%20and%20Geography/subTopic/Travel",
  "Food and Cooking": "/search/allTopics/Food%20and%20Cooking",
  Music: "/search/allTopics/Music",
  Politics:
    "/search/allTopics/Politics%20and%20Law/subTopic/Geopolitics_Infrastructure%20and%20Transports_Politics",
};
