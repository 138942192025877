import React, { useEffect, useState } from "react";
import moment from "../../node_modules/moment/moment";

export default function ThumbnailVideoNoLike(props) {
  const { c, setVideoPlayed, setChannelPlayed, channelName, channelId } = props;

  const [imageExist, setImageExist] = useState(true);

  // Function to remove the videos that don't exist anymore

  const getImageHeight = (url, callback) => {
    let img = new Image();
    img.onload = () => callback(img.height);
    img.onerror = () => callback(null);
    img.src = url;
  };

  useEffect(() => {
    setImageExist(true);
    getImageHeight(c.thumbnail, (height) => {
      if (height !== null) {
        if (height == 90) {
          setImageExist(false);
        }
      }
    });
  }, [c]);

  return (
    <div className="thumbnailWrapper">
      {imageExist && (
        <div className="videoThumbnailsBrowse">
          <div className="alignVertical">
            <div className="thumbnailContainer">
              <a className="durationInfo">{c.duration}min</a>
              <img
                loading="lazy"
                src={c.thumbnail}
                className="browseMedium"
                onClick={() => {
                  setVideoPlayed(c);
                  setChannelPlayed([channelName, channelId]);
                }}
                alt={c.title + " video thumbnail"}
              />
              <div className="thumbnailHover">
                <i
                  className="fa-solid fa-play"
                  id="toPlay"
                  onClick={() => {
                    setVideoPlayed(c);
                    setChannelPlayed([channelName, channelId]);
                  }}
                ></i>
              </div>
            </div>
            <a
              onClick={() => {
                setVideoPlayed(c);
                setChannelPlayed([channelName, channelId]);
              }}
              id="moreSpaceUp"
            >
              {c.title.split(/\s+/).slice(0, 15).join(" ")}
              {c.title.match(/(\w+)/g) && c.title.match(/(\w+)/g).length > 15
                ? "..."
                : ""}
            </a>
            <a
              href={`/channel/${
                channelName &&
                channelName
                  .replaceAll(" ", "_")
                  .replaceAll("-", "_")
                  .toLowerCase()
              }-${channelId}`}
            >
              <text>{channelName}</text> <text>-</text>{" "}
              <text>
                {c.nbViews > 1000000
                  ? Math.round(c.nbViews / 100000) / 10 + "M"
                  : c.nbViews > 10000
                  ? Math.round(c.nbViews / 1000) + "K"
                  : c.nbViews > 1000
                  ? Math.round(c.nbViews / 100) / 10 + "K"
                  : c.nbViews}{" "}
                views
              </text>{" "}
              <text>-</text> <text>{moment(c.publishDate).fromNow()}</text>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
