import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div>
      {" "}
      <div className="mainElement">
        <div className="form">
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <h1>Oooops! This page doesn't exist 🫣</h1>

          <div className="space"></div>
          <Link to="/">
            <p>
              <i className="fa-solid fa-house"></i> Back to home
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
