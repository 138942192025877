import {
  REVIEWS_PROGRAM_FAIL,
  REVIEWS_PROGRAM_REQUEST,
  REVIEWS_PROGRAM_SUCCESS,
} from "../constants/reviewConstants";

export const reviewsFromProgramReducer = (
  state = { loadingQuery: true, reviewsQuery: [] },
  action
) => {
  switch (action.type) {
    case REVIEWS_PROGRAM_REQUEST:
      return { loadingQuery: true };
    case REVIEWS_PROGRAM_SUCCESS:
      return { loadingQuery: false, reviewsQuery: action.payload };
    case REVIEWS_PROGRAM_FAIL:
      return { loadingQuery: false, errorQuery: action.payload };
    default:
      return state;
  }
};
