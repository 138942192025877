import React from "react";

export default function Rating(props) {
  const { rating, numReviews, caption, mean } = props;
  return (
    <div className="rating">
      {mean && <span> {Math.round(rating * 10) / 10} </span>}

      <span>
        <i
          className={
            rating >= 0.75
              ? "fa-solid fa-star"
              : rating >= 0.25
              ? "fa-solid fa-star-half-stroke"
              : "fa-regular fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 1.75
              ? "fa-solid fa-star"
              : rating >= 1.25
              ? "fa-solid fa-star-half-stroke"
              : "fa-regular fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 2.75
              ? "fa-solid fa-star"
              : rating >= 2.25
              ? "fa-solid fa-star-half-stroke"
              : "fa-regular fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 3.75
              ? "fa-solid fa-star"
              : rating >= 3.25
              ? "fa-solid fa-star-half-stroke"
              : "fa-regular fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 4.75
              ? "fa-solid fa-star"
              : rating >= 4.25
              ? "fa-solid fa-star-half-stroke"
              : "fa-regular fa-star"
          }
        ></i>
      </span>
      {caption ? (
        <span>{caption}</span>
      ) : (
        <span> {"(" + numReviews + ")"} </span>
      )}
    </div>
  );
}
