import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "../../node_modules/react-router-dom/index.js";
import { useAuth } from "../AuthContext.js";
import {
  getDetailsUser,
  listPlaylist,
  signout,
} from "../actions/userActions.js";

export default function LoginHeader(props) {
  const dispatch = useDispatch();

  const signoutHandler = () => {
    dispatch(signout());
  };
  const { currentUser, logout } = useAuth();
  const [errorLogin, setErrorLogin] = useState("");

  async function handleLogout() {
    setErrorLogin("");
    try {
      await logout().then(signoutHandler);
    } catch {
      setErrorLogin("Failed to log out");
    }
  }

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, [currentUser, dispatch]);

  const refreshPage = () => {
    window.location.reload();
  };

  /*
  const listPlaylistUser = useSelector((state) => state.listPlaylist);
  const { playlist2s } = listPlaylistUser;  */

  useEffect(() => {
    if (currentUser) {
      dispatch(listPlaylist(currentUser.auth.currentUser.accessToken));
    }
  }, [dispatch]);

  /*
  const listPlaylistUser = useSelector((state) => state.listPlaylist);
  const { playlist2s } = listPlaylistUser;

  useEffect(() => {
    if (currentUser) {
      dispatch(listPlaylist(currentUser.auth.currentUser.accessToken));
    }
  }, [dispatch]);  */

  /*
  {
    userInfo ? (
      <div>
        <li>
          <Link to="/profile" onClick={toggleOffer}>
            {" "}
            My profile {({userInfo.name })}{" "}
          </Link>
        </li>

        <li>
          <Link to="#signout" onClick={handleLogout}>
            Sign Out
          </Link>
        </li>
      </div>
    ) : (
        <Link className="noHover" to="/register2">
            <button className="signUpButton"> Sign up</button>
          </Link>
          <Link className="noHover" to="/signin2">
            <button className="buttonLogin"> Sign in</button>
          </Link>
    );
  }

  */

  return (
    <div>
      {currentUser ? (
        <div>
          {user && (
            <li>
              <Link to={`/user/${user.userId}`} onClick={props.toggleOffer}>
                {" "}
                <i class="fa-solid fa-user"></i> My profile
              </Link>
            </li>
          )}
          {user && (
            <li>
              <Link to="/settings" onClick={props.toggleOffer}>
                <i class="fa-solid fa-gear"></i> Settings{" "}
              </Link>
            </li>
          )}
          <li>
            <Link to="#signout" onClick={handleLogout}>
              <i class="fa-solid fa-right-from-bracket"></i> Sign Out
            </Link>
          </li>
        </div>
      ) : (
        <div>
          <Link className="noHover" to="/register" onClick={props.toggleOffer}>
            <button className="signUpButton"> Sign up</button>
          </Link>
          <Link className="noHover" to="/signin" onClick={props.toggleOffer}>
            <button className="buttonLogin"> Log in</button>
          </Link>
        </div>
      )}
    </div>
  );
}

/*    <text>{currentUser.email}</text>
              <text>{currentUser.uid}</text> */
