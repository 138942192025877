import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");

  const [errorLogin, setErrorLogin] = useState("");
  const [message, setMessage] = useState("");

  const [loadingLogin, setLoadingLogin] = useState(false);

  const { resetPassword } = useAuth();

  async function submitHandler(e) {
    e.preventDefault();

    try {
      setMessage("");
      setErrorLogin("");
      setLoadingLogin(true);
      await resetPassword(email);
      setMessage("Email sent! Check your inbox for further instructions");
    } catch {
      setErrorLogin("Failed to reset password");
    }

    setLoadingLogin(false);
  }

  return (
    <div className="mainElement">
      <form className="form" onSubmit={submitHandler}>
        <div className="space"></div>
        <div>
          <h2>Password reset</h2>
        </div>

        {errorLogin && <text className="error">{errorLogin}</text>}

        <div>
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            id="email"
            placeholder="Please enter your email address"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label />
          <button disable={loadingLogin} className="primary" type="submit">
            Reset password
          </button>
        </div>
        {message && (
          <div>
            {" "}
            <div className="space"></div>{" "}
            <text className="success">{message}</text>
          </div>
        )}

        <div>
          <div className="space"></div>

          <div>
            <Link to={`/signin`}>Log in</Link>
          </div>
          <label />
          <div className="space"></div>
        </div>
      </form>
    </div>
  );
}
