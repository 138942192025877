import React, { useEffect } from "react";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function ContributionSentScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mainElement">
      <Helmet>
        <title>Message submitted | Favoree</title>
        <meta
          name="description"
          content="Your message has been submitted"
        ></meta>

        <link
          rel="canonical"
          href="https://www.favoree.io/contribution-submitted"
        ></link>
      </Helmet>
      <div className="form">
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        <h1>Message submitted ✅</h1>
        <h2>Thaaaaaaaaaanks for helping us out to improve Favoree!</h2>
      </div>
    </div>
  );
}
