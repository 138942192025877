import React from "react";

export default function UserPicture(props) {
  return (
    <img
      src={
        props.uid
          ? process.env.REACT_APP_PHOTO_FILE_URL + `%2F${props.uid}?alt=media`
          : "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media"
      }
      onError={(e) => {
        if (
          !e.target.src.endsWith(
            "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media"
          )
        ) {
          e.target.src =
            "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media";
        }
      }}
      alt="User Profile Picture"
      className={props.imageSize}
      id="imageOnProfile"
    />
  );
}
