import {
  PRODUCT_LIKE_REVIEW_CREATE_FAIL,
  PRODUCT_LIKE_REVIEW_CREATE_REQUEST,
  PRODUCT_LIKE_REVIEW_CREATE_SUCCESS,
  PRODUCT_LIKE_REVIEW_DELETE_FAIL,
  PRODUCT_LIKE_REVIEW_DELETE_REQUEST,
  PRODUCT_LIKE_REVIEW_DELETE_SUCCESS,
} from "../constants/likeReviewConstants";

export const likeReviewCreateReducer = (
  state = { loadingQuery: true, reviewsQuery: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIKE_REVIEW_CREATE_REQUEST:
      return { loadingQuery: true };
    case PRODUCT_LIKE_REVIEW_CREATE_SUCCESS:
      return { loadingQuery: false, reviewsQuery: action.payload };
    case PRODUCT_LIKE_REVIEW_CREATE_FAIL:
      return { loadingQuery: false, errorQuery: action.payload };
    default:
      return state;
  }
};

export const likeReviewDeleteReducer = (
  state = { loadingQuery: true, reviewsQuery: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIKE_REVIEW_DELETE_REQUEST:
      return { loadingQuery: true };
    case PRODUCT_LIKE_REVIEW_DELETE_SUCCESS:
      return { loadingQuery: false, reviewsQuery: action.payload };
    case PRODUCT_LIKE_REVIEW_DELETE_FAIL:
      return { loadingQuery: false, errorQuery: action.payload };
    default:
      return state;
  }
};
