import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ThumbnailVideo from "../components/ThumbnailVideo";

import LoadingBox from "./LoadingBox";
import { topicList, topicUrl } from "./TopicsData";
import Axios from "axios";

export default function BrowseVideos(props) {
  function getFilterUrl(x) {
    /*this.history.push(`/${x}`);  */
    props.setNbGeneralVideos(0);
    props.setTopic(x);
  }

  const isMobile = !useMediaQuery({
    query: "(min-width: 600px)",
  });

  async function sendReco(channelName, videoTitle, recommendation) {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_ADDRESS + `recommendations`,
      {
        channelName: channelName,
        videoTitle: videoTitle,
        recommendation: recommendation,
      }
    );

    document.getElementById(
      "thumbs" + videoTitle.substring(0, 12)
    ).style.display = "none";
  }

  return (
    <tr>
      {!isMobile && (
        <td className="tdFilter">
          <h2>Explore videos</h2>
          <ul className="filterHP">
            {Object.keys(topicList).map((c) => (
              <li key={c}>
                <a
                  className={props.topic.includes(c) ? "active" : "notactive"}
                  onClick={() => getFilterUrl(c)}
                >
                  <a className="topicFilter">
                    <input
                      type="checkbox"
                      checked={props.topic.includes(c) ? "checked" : ""}
                    ></input>
                    {topicList[c]} &nbsp;&nbsp;
                    {c.replace("and", "&")}
                  </a>
                </a>
              </li>
            ))}
          </ul>

          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          {props.topic !== "All" && (
            <text>
              More channels about {props.topic}{" "}
              <Link to={topicUrl[props.topic]}>here</Link>
            </text>
          )}
        </td>
      )}
      <td className="tdCentral">
        <div className="row center">
          {isMobile && (
            <td>
              <div className="filterSlider">
                <ul className="ulFilter">
                  {Object.keys(topicList).map((c) => (
                    <a
                      className={
                        props.topic.includes(c) ? "active" : "notactive"
                      }
                      onClick={() => getFilterUrl(c)}
                    >
                      <li key={c}>
                        <a className="topicFilter">
                          <input
                            type="checkbox"
                            checked={props.topic.includes(c) ? "checked" : ""}
                          ></input>
                          {topicList[c]}
                          {c.replace("and", "")}
                        </a>
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
            </td>
          )}
          {props.generalVideos &&
          props.generalVideos.slice(0, props.nbGeneralVideos + 18).length >
            0 ? (
            props.generalVideos
              .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
              .slice(0, props.nbGeneralVideos + 18)

              .map((c) => (
                <ThumbnailVideo
                  c={c}
                  setVideoPlayed={props.setVideoPlayed}
                  setChannelPlayed={props.setChannelPlayed}
                  sendReco={sendReco}
                ></ThumbnailVideo>
              ))
          ) : props.generalVideos &&
            props.generalVideos.slice(0, props.nbGeneralVideos + 18).length ==
              0 ? (
            <div className="videoThumbnailsBrowse">
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="space"></div>
              <div className="tdCentral">
                <a>
                  No more results, please come back in few hours for new fresh
                  videos{" "}
                </a>
              </div>
              <div className="space"></div>
              <div className="space"></div>
            </div>
          ) : (
            <div>
              <LoadingBox></LoadingBox>
            </div>
          )}
        </div>
        <td className="centeredBrowse">
          {props.generalVideos &&
          props.generalVideos.slice(
            props.nbGeneralVideos,
            props.nbGeneralVideos + 18
          ).length > 0 ? (
            <button
              className="buttonBrowseMore"
              id="spin"
              onClick={() => {
                props.setNbGeneralVideos(props.nbGeneralVideos + 18);
              }}
            >
              <i className="fa-solid fa-chevron-down"></i> Browse more videos
            </button>
          ) : props.generalVideos.length >= 10 ? (
            <text>No more results</text>
          ) : (
            <div></div>
          )}
        </td>
      </td>{" "}
    </tr>
  );
}
