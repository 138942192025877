import Axios from "axios";
import {
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_LANGUAGE_LIST_FAIL,
  PRODUCT_LANGUAGE_LIST_REQUEST,
  PRODUCT_LANGUAGE_LIST_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_ALLTOPIC_CREATE_FAIL,
  PRODUCT_ALLTOPIC_CREATE_REQUEST,
  PRODUCT_ALLTOPIC_CREATE_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_ALLTOPICS_LIST_REQUEST,
  PRODUCT_ALLTOPICS_LIST_SUCCESS,
  PRODUCT_ALLTOPICS_LIST_FAIL,
  PRODUCT_MOOD_LIST_REQUEST,
  PRODUCT_CONTEXT_LIST_REQUEST,
  PRODUCT_CONTEXT_LIST_SUCCESS,
  PRODUCT_CONTEXT_LIST_FAIL,
  PRODUCT_MOOD_LIST_SUCCESS,
  PRODUCT_MOOD_LIST_FAIL,
  PRODUCT_LEVEL_LIST_REQUEST,
  PRODUCT_LEVEL_LIST_SUCCESS,
  PRODUCT_LEVEL_LIST_FAIL,
  PRODUCT_TAG_LIST_REQUEST,
  PRODUCT_TAG_LIST_SUCCESS,
  PRODUCT_TAG_LIST_FAIL,
  PRODUCT_PLATFORM_LIST_REQUEST,
  PRODUCT_PLATFORM_LIST_SUCCESS,
  PRODUCT_PLATFORM_LIST_FAIL,
  PRODUCT_REVIEW_DELETE_REQUEST,
  PRODUCT_REVIEW_DELETE_SUCCESS,
  PRODUCT_REVIEW_DELETE_FAIL,
  PRODUCT_SUBTOPIC_LIST_REQUEST,
  PRODUCT_SUBTOPIC_LIST_SUCCESS,
  PRODUCT_SUBTOPIC_LIST_FAIL,
  PRODUCT_QUERY_LIST_REQUEST,
  PRODUCT_QUERY_LIST_SUCCESS,
  PRODUCT_QUERY_LIST_FAIL,
  VIDEO_QUERY_LIST_REQUEST,
  VIDEO_QUERY_LIST_SUCCESS,
  VIDEO_QUERY_LIST_FAIL,
  PRODUCT_COUNTRY_LIST_REQUEST,
  PRODUCT_COUNTRY_LIST_SUCCESS,
  PRODUCT_COUNTRY_LIST_FAIL,
  PRODUCT_NAME_QUERY_LIST_REQUEST,
  PRODUCT_NAME_QUERY_LIST_SUCCESS,
  PRODUCT_NAME_QUERY_LIST_FAIL,
} from "../constants/productConstants";

export const listProducts =
  ({
    name = "",
    category = "",
    country = "",
    order = "",
    min = 0,
    max = 0,
    rating = 0,
    ratingFun = 0,
    ratingLearn = 0,
    ratingEmotional = 0,
    language = "English__French",
    duration = "",
    subscriber = "",
    context = "",
    level = "",
    videoToWatch = "",
    tag = "",
    platform = "",
    allTopics = "",
    subTopic = "",
    rankingRatingMoodDef = "",
  }) =>
  async (dispatch) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    try {
      const { data } = await Axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products?name=${name}&category=${category}&country=${country}&min=${min}&max=${max}&rating=${rating}&ratingFun=${ratingFun}&ratingLearn=${ratingLearn}&ratingEmotional=${ratingEmotional}&order=${order}&language=${language}&allTopics=${allTopics}&subTopic=${subTopic}&rankingRatingMoodDef=${rankingRatingMoodDef}&context=${context}&level=${level}&platform=${platform}&tag=${tag}&duration=${duration}&subscriber=${subscriber}`
      );
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    }
  };

export const listProductsCategories = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_CATEGORY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/categories`
    );
    dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_CATEGORY_LIST_FAIL, payload: error.message });
  }
};

export const listProductsCountries = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_COUNTRY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/countries`
    );
    dispatch({ type: PRODUCT_COUNTRY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_COUNTRY_LIST_FAIL, payload: error.message });
  }
};

export const listProductsContexts = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_CONTEXT_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/contexts`
    );
    dispatch({ type: PRODUCT_CONTEXT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_CONTEXT_LIST_FAIL, payload: error.message });
  }
};

export const listProductsLanguages = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LANGUAGE_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/languages`
    );
    dispatch({ type: PRODUCT_LANGUAGE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_LANGUAGE_LIST_FAIL, payload: error.message });
  }
};

export const listProductsMoods = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_MOOD_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/moods`
    );
    dispatch({ type: PRODUCT_MOOD_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_MOOD_LIST_FAIL, payload: error.message });
  }
};

export const listProductsLevels = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LEVEL_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/levels`
    );
    dispatch({ type: PRODUCT_LEVEL_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_LEVEL_LIST_FAIL, payload: error.message });
  }
};

export const listProductsTags = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_TAG_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/tags`
    );
    dispatch({ type: PRODUCT_TAG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_TAG_LIST_FAIL, payload: error.message });
  }
};

export const listProductsPlatforms = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_PLATFORM_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/platforms`
    );
    dispatch({ type: PRODUCT_PLATFORM_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_PLATFORM_LIST_FAIL, payload: error.message });
  }
};

export const listProductsAllTopicss = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_ALLTOPICS_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/allTopicss`
    );
    dispatch({ type: PRODUCT_ALLTOPICS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_ALLTOPICS_LIST_FAIL, payload: error.message });
  }
};

export const listProductsSubTopics = (x) => async (dispatch) => {
  dispatch({
    type: PRODUCT_SUBTOPIC_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/subTopics/${x}`
    );
    dispatch({ type: PRODUCT_SUBTOPIC_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_SUBTOPIC_LIST_FAIL, payload: error.message });
  }
};

export const detailsProduct = (productId) => async (dispatch) => {
  dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS +
        `products/channels/${
          Array.isArray(productId) ? productId.join("_") : productId
        }/getvideos/true`
    );
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_ADDRESS + "products",
      {},
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_CREATE_FAIL, payload: message });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_ADDRESS + `products/${product._id}`,
      product,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_UPDATE_FAIL, error: message });
  }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = Axios.delete(
      process.env.REACT_APP_API_ADDRESS + `products/${productId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: PRODUCT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_DELETE_FAIL, payload: message });
  }
};

export const createAllTopic =
  (productId, allTopic) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_ALLTOPIC_CREATE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_API_ADDRESS + `products/${productId}/allTopics`,
        allTopic,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: PRODUCT_ALLTOPIC_CREATE_SUCCESS,
        payload: data.allTopic,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_ALLTOPIC_CREATE_FAIL, payload: message });
    }
  };

export const listProductNamesQuery = (query) => async (dispatch) => {
  dispatch({
    type: PRODUCT_NAME_QUERY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/search/channelname/${query}`
    );

    dispatch({ type: PRODUCT_NAME_QUERY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_NAME_QUERY_LIST_FAIL, payload: error.message });
  }
};

export const listProductsQuery = (query) => async (dispatch) => {
  dispatch({
    type: PRODUCT_QUERY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/query/${query}`
    );
    dispatch({ type: PRODUCT_QUERY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_QUERY_LIST_FAIL, payload: error.message });
  }
};

export const listVideosQuery = (query) => async (dispatch) => {
  dispatch({
    type: VIDEO_QUERY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `products/query/videos/${query}`
    );
    dispatch({ type: VIDEO_QUERY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VIDEO_QUERY_LIST_FAIL, payload: error.message });
  }
};
