import React, { useState } from "react";
import { Link } from "../../node_modules/react-router-dom/index";
import Rating from "../components/Rating";
import { useDispatch } from "react-redux";
import { deleteReview } from "../actions/reviewActions";
import LikeCountReview from "../components/LikeCountReview";
import UserPicture from "./UserPicture";

export default function Review(props) {
  const review = props.review;
  const currentUser = props.currentUser;
  const channelNameRemoved = props.channelNameRemoved;
  const reviewRemoval = props.reviewRemoval;

  const [longReviewDeployed, setLongReviewDeployed] = useState(false);

  const dispatch = useDispatch();

  return (
    <li className="review" key={review._id}>
      <div className="row">
        <div className="column1">
          <div className="alignHorizontal">
            {channelNameRemoved && (
              <UserPicture
                uid={review.uid}
                imageSize="avatarPictureSmall"
              ></UserPicture>
            )}
            {!channelNameRemoved && (
              <Link
                to={`/channel/${review.creatorName
                  .replaceAll(" ", "_")
                  .replaceAll("-", "_")
                  .toLowerCase()}-${review.creatorId}`}
              >
                <div className="alignHorizontal">
                  <img
                    className="smallRound"
                    src={review.profileImage[0]}
                    alt={review.name}
                  />
                  <div className="space"></div>
                  <div>
                    <text>Review of the channel</text>

                    <h3>{review.creatorName}</h3>
                  </div>
                </div>
              </Link>
            )}
            {review._id && review.comment && review.comment.length > 1 ? (
              <div className="likeReview">
                <LikeCountReview
                  reviewId={review._id}
                  userToken={
                    currentUser ? currentUser.auth.currentUser.accessToken : ""
                  }
                  userIdReviewing={review.uid ? review.uid : ""}
                  uid={currentUser ? currentUser.uid : ""}
                ></LikeCountReview>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <text>By</text>
          <Link to={`/user/${review.userId}`}>
            <strong id="italic"> {review.userName}</strong>{" "}
          </Link>
          <Link
            to={`/channel/${review.creatorName
              .replaceAll(" ", "_")
              .replaceAll("-", "_")
              .toLowerCase()}-${review.creatorId}`}
          ></Link>
          <Rating caption=" " rating={review.rating}></Rating>
          <p>
            {review.comment.substring(
              0,
              longReviewDeployed
                ? 20000
                : review.comment.length >= 600
                ? 400
                : 600
            )}
          </p>
          {review.comment.length >= 600 && !longReviewDeployed ? (
            <div className="absolute">
              <div className="fadeout"> </div>
              <button
                className="moreFilters"
                onClick={() => setLongReviewDeployed(true)}
              >
                <i class="fa-solid fa-chevron-down"></i> More
              </button>
            </div>
          ) : (
            <div></div>
          )}

          <div className="space"></div>
          {review.moodList && review.moodList != "" ? (
            <div className="topics">
              <text>User found it</text>

              <ul>
                {review.moodList.map((c) => (
                  <div className="topic"> {c} </div>
                ))}
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="column1">
          <h5>{"Published " + review.createdAt.substring(0, 10)}</h5>
        </div>
        {reviewRemoval && currentUser && review.uid == currentUser.uid ? (
          <div>
            <div className="space"></div>
            <div className="space"></div>

            <button
              className="buttondelete"
              onClick={() => {
                dispatch(
                  deleteReview(
                    review.creatorId,
                    currentUser.auth.currentUser.accessToken
                  )
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            >
              Remove my review <i className="fa-regular fa-trash-can"></i>
            </button>
          </div>
        ) : (
          <div> </div>
        )}
      </div>
    </li>
  );
}
