import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { detailsProduct } from "../actions/productActions";
import { userRemovePlaylist } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import { useAuth } from "../AuthContext";
import axios from "../../node_modules/axios/index";
import TopSimilarFavorites from "../components/TopSimilarFavorites";
import ThumbnailVideoNoLike from "../components/ThumbnailVideoNoLike";
import LaunchVideo from "../components/LaunchVideo";

export default function PlaylistScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkoutHandler = () => {
    props.history.push("/add-channel");
  };

  const { currentUser } = useAuth();

  const dispatch = useDispatch();

  const listPlaylist = useSelector((state) => state.listPlaylist);
  const { playlist2s } = listPlaylist;

  const [nbFavorites, setNbFavorites] = useState(0);
  const [similarChannelsList, setSimilarChannelsList] = useState("");

  const productDetails = useSelector((state) => state.productDetails);
  const { product } = productDetails;

  const [videoPlayed, setVideoPlayed] = useState("");
  const [channelPlayed, setChannelPlayed] = useState("");

  useEffect(() => {
    if (playlist2s && playlist2s[0]) {
      dispatch(detailsProduct(playlist2s));
      setNbFavorites(new Set(playlist2s).size);
    }
  }, [dispatch, playlist2s]);

  const removeItemPlaylist = (productId) => {
    dispatch(
      userRemovePlaylist(productId, currentUser.auth.currentUser.accessToken)
    );

    const id = playlist2s.indexOf(productId);
    playlist2s.splice(id, 1);

    dispatch(detailsProduct(playlist2s));

    setNbFavorites(new Set(playlist2s).size);
  };

  useEffect(() => {
    async function launchTopSimilar(x) {
      let responseSimilarTable = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `similars/` + x
      );
      const similarTable = responseSimilarTable.data;

      // Sum of the commonscores by channelId to find the most similar channels

      var similarChannels = [];
      similarTable.reduce(function (res, value) {
        if (!res[value.similarNameID]) {
          res[value.similarNameID] = {
            similarNameID: value.similarNameID,
            similarName: value.similarName,

            commonScore: 0,
          };
          similarChannels.push(res[value.similarNameID]);
        }
        res[value.similarNameID].commonScore += value.commonScore;
        return res;
      }, {});

      const bestSimilarChannels = similarChannels
        .sort((a, b) => b.commonScore - a.commonScore)
        .slice(0, 15);

      const bestSimilarChannelsString = bestSimilarChannels
        .map((a) => a.similarNameID)
        .join("_");

      let responseRanking = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products/channels/${bestSimilarChannelsString}/getvideos/false`
      );

      //order function to rank the channels by their similarity Score
      let order = new Map();
      bestSimilarChannels.forEach((item, index) => {
        order.set(item.similarNameID, index);
      });

      const rankingData = responseRanking.data
        .filter((review) => (review.rating >= 3) | (review.rating == 0))
        .filter((review) => !playlist2s.includes(review._id))
        .sort((a, b) => {
          return order.get(a._id) - order.get(b._id);
        });

      setSimilarChannelsList(rankingData);
    }
    if (playlist2s && playlist2s[0]) {
      launchTopSimilar(playlist2s.join("_"));
    }
  }, [dispatch, playlist2s]);

  const isMobile = !useMediaQuery({
    query: "(min-width: 1088px)",
  });

  const isTablet = !useMediaQuery({
    query: "(min-width: 1368px)",
  });

  return (
    <div className="mainElement">
      <div className="space"></div>

      <div className="row top">
        <div className="colPlaylist">
          <div className="alignHorizontal" id="toRight">
            <h1>
              My favorites ({nbFavorites} program
              {nbFavorites > 1 ? "s" : ""}){" "}
            </h1>
            {isTablet && nbFavorites > 0 ? (
              <button
                className="sortVideos"
                onClick={() => {
                  document.getElementById("topSimilarSection").scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                  });
                }}
              >
                <i class="fa-solid fa-ranking-star"></i> Find similar channels
                to your favorites
              </button>
            ) : (
              <div></div>
            )}
          </div>
          <div className="space"></div>

          {!currentUser ? (
            <MessageBox>
              Please <Link to="/signin">sign in</Link> to add channels to your
              favorites
            </MessageBox>
          ) : !product ? (
            <MessageBox>
              Your watchlist is empty,{" "}
              <Link to="/search">please find channels here.</Link>
              <text>
                {" "}
                When you like channels, you get new channel suggestions on this
                page.
              </text>
            </MessageBox>
          ) : product ? (
            <div className="backgroundTable">
              <table>
                <colgroup>
                  <col width="102"></col>
                  <col width="200"></col>
                  <col width="480"></col>
                  <col width="70"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>Channel</th>
                    <th>Tags</th>
                    <th>Latest videos</th>
                    <th>
                      <i className="fa-solid fa-trash-can"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {product &&
                    (product[0] ? product : [product]).map((product, index) => (
                      <tr>
                        <td>
                          <div className="alignVertical">
                            <Link
                              to={`/channel/${product.name
                                .replaceAll(" ", "_")
                                .replaceAll("-", "_")
                                .toLowerCase()}-${product._id}`}
                            >
                              <img
                                src={product.profileImage}
                                alt={product.name}
                                className="profileImagePlaylist"
                              ></img>
                              <div className="space"></div>
                              <div id="namePlaylist">
                                <Link
                                  to={`/channel/${product.name
                                    .replaceAll(" ", "_")
                                    .replaceAll("-", "_")
                                    .toLowerCase()}-${product._id}`}
                                >
                                  {" "}
                                  {product.name}{" "}
                                </Link>
                              </div>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div className="topics">
                            <ul>
                              <a className="topic">{product.category} </a>
                              {product.allTopics.map((topic) => (
                                <a className="topic">{topic.mainTopic} </a>
                              ))}
                              {product.allTopics.map((topic) => (
                                <a className="topic">{topic.subTopic} </a>
                              ))}
                              {product.tag.map((x) => (
                                <li key={x}>
                                  <a className="topic">{x} </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div className="alignHorizontal">
                            {product.lastVideos &&
                              product.lastVideos
                                .filter((a) => a.nbViews >= 10)
                                .sort(
                                  (a, b) =>
                                    new Date(b.publishDate) -
                                    new Date(a.publishDate)
                                )
                                .slice(0, 2)
                                .map((c) => (
                                  <ThumbnailVideoNoLike
                                    c={c}
                                    setVideoPlayed={setVideoPlayed}
                                    setChannelPlayed={setChannelPlayed}
                                    channelName={product.name}
                                    channelId={product._id}
                                  ></ThumbnailVideoNoLike>
                                ))}
                          </div>
                        </td>
                        <td>
                          <div className="center">
                            {!isMobile ? (
                              <button
                                className="buttonPlaylistDelete"
                                onClick={() =>
                                  removeItemPlaylist(product._id, playlist2s)
                                }
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            ) : (
                              <div>
                                <button
                                  className="buttondelete"
                                  onClick={() =>
                                    removeItemPlaylist(product._id, playlist2s)
                                  }
                                >
                                  <i className="fa-solid fa-trash-can"></i>{" "}
                                  Remove
                                </button>
                                <div className="space"></div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="space"></div>
          )}
        </div>
        {similarChannelsList && similarChannelsList.length > 0 ? (
          <div className="col-1" id="similarPlaylist">
            <div className="space" id="topSimilarSection"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>

            <div className="topSimilarPlaylist">
              <h3>
                <i class="fa-solid fa-ranking-star"></i> Most similar channels
                to your favorites
              </h3>
              <div className="space"></div>
              <TopSimilarFavorites
                similarChannelsList={similarChannelsList.slice(
                  0,
                  nbFavorites > 3 ? 10 : 6
                )}
              ></TopSimilarFavorites>
            </div>
          </div>
        ) : (
          <div> </div>
        )}
      </div>

      <div className="card card-body2">
        <ul>
          <li>
            <i className="fa-regular fa-paper-plane"></i>
            <p>
              If you have any favorite content that is not yet on our website,
              send it us !
            </p>
          </li>
          <li>
            <button
              type="button"
              onClick={checkoutHandler}
              className="duocolorbutton"
              /* disabled={playlistItems.length === 0} */
            >
              Add new content
            </button>
          </li>
        </ul>
      </div>
      {videoPlayed && (
        <LaunchVideo
          videoPlayed={videoPlayed}
          channelPlayed={channelPlayed}
          setVideoPlayed={setVideoPlayed}
          setChannelPlayed={setChannelPlayed}
        ></LaunchVideo>
      )}
    </div>
  );
}
