import React from "react";

export default function ChannelSentScreen() {
  return (
    <div>
      <div className="form">
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>

        <h1>🫶🌟🎉 Channel submitted ✅ 🎉🌟🫶</h1>
        <h2>Thanks for helping us out to improve Favoree!</h2>
        <p>
          We will review the content ASAP and will upload it on Favoree if the
          content matches our standards
        </p>
        <a className="button2" href="https://tally.so/r/nPOvvQ">
          {" "}
          Submit another channel
        </a>
      </div>
    </div>
  );
}
