import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_DETAILS_RESET,
  USER_PLAYLIST2_REQUEST,
  USER_PLAYLIST2_SUCCESS,
  USER_PLAYLIST2_FAIL,
  USER_PLAYLIST2_RESET,
  USER_PLAYLIST_LIST_REQUEST,
  USER_PLAYLIST_LIST_SUCCESS,
  USER_PLAYLIST_LIST_FAIL,
  USER_PLAYLIST_LIST_RESET,
  USER_REMOVE_PLAYLIST_REQUEST,
  USER_REMOVE_PLAYLIST_SUCCESS,
  USER_REMOVE_PLAYLIST_FAIL,
  USER_QUERY_LIST_REQUEST,
  USER_QUERY_LIST_SUCCESS,
  USER_QUERY_LIST_FAIL,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userAddPlaylistReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PLAYLIST2_REQUEST:
      return { loading: true };
    case USER_PLAYLIST2_SUCCESS:
      return { loading: false, success: true };
    case USER_PLAYLIST2_FAIL:
      return { loading: false, error: action.payload };
    case USER_PLAYLIST2_RESET:
      return {};
    default:
      return state;
  }
};

export const userRemovePlaylistReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REMOVE_PLAYLIST_REQUEST:
      return { loading: true };
    case USER_REMOVE_PLAYLIST_SUCCESS:
      return { loading: false, success: true };
    case USER_REMOVE_PLAYLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (
  state = { loading: true, user: [] },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};

export const userGetPlaylistReducer = (
  state = { loading: true, playlist2s: [] },

  action
) => {
  switch (action.type) {
    case USER_PLAYLIST_LIST_REQUEST:
      return { loading: true };
    case USER_PLAYLIST_LIST_SUCCESS:
      return { loading: false, playlist2s: action.payload };
    case USER_PLAYLIST_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_PLAYLIST_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const userListQueryReducer = (
  state = { loadingUserQuery: true, usersQuery: [] },
  action
) => {
  switch (action.type) {
    case USER_QUERY_LIST_REQUEST:
      return { loadingUserQuery: true };
    case USER_QUERY_LIST_SUCCESS:
      return { loadingUserQuery: false, usersQuery: action.payload };
    case USER_QUERY_LIST_FAIL:
      return { loadingUserQuery: false, errorUsersQuery: action.payload };
    default:
      return state;
  }
};
