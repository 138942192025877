import Axios from "axios";
import {
  PRODUCT_LIKE_REVIEW_CREATE_FAIL,
  PRODUCT_LIKE_REVIEW_CREATE_SUCCESS,
  PRODUCT_LIKE_REVIEW_DELETE_FAIL,
  PRODUCT_LIKE_REVIEW_DELETE_SUCCESS,
} from "../constants/likeReviewConstants";

export const deleteLikeReview =
  (reviewId, token) => async (dispatch, getState) => {
    try {
      const { data } = Axios.delete(
        process.env.REACT_APP_API_ADDRESS + `likeReviews/${reviewId}`,
        { data: { token } }
      );

      dispatch({
        type: PRODUCT_LIKE_REVIEW_DELETE_SUCCESS,
        payload: "removed",
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_LIKE_REVIEW_DELETE_FAIL, payload: message });
    }
  };

export const createLikeReview =
  (reviewId, token) => async (dispatch, getState) => {
    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_API_ADDRESS + `likeReviews/${reviewId}`,
        { token }
      );

      dispatch({
        type: PRODUCT_LIKE_REVIEW_CREATE_SUCCESS,
        payload: data.likeReview,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_LIKE_REVIEW_CREATE_FAIL, payload: message });
    }
  };
