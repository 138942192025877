import React from "react";
import Review from "../components/Review";

export default function Reviews(props) {
  const { reviewList, currentUser, channelNameRemoved, reviewRemoval } = props;
  return (
    <div className="row center">
      {reviewList &&
        reviewList
          /* Removing Too many Negative Reviews  */
          .map((review) => (
            <div>
              <Review
                review={review}
                currentUser={currentUser}
                channelNameRemoved={channelNameRemoved}
                reviewRemoval={reviewRemoval}
              ></Review>
            </div>
          ))}
    </div>
  );
}
