export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_NAME_QUERY_LIST_REQUEST =
  "PRODUCT_NAME_QUERY_LIST_REQUEST";
export const PRODUCT_NAME_QUERY_LIST_SUCCESS =
  "PRODUCT_NAME_QUERY_LIST_SUCCESS";
export const PRODUCT_NAME_QUERY_LIST_FAIL = "PRODUCT_NAME_QUERY_LIST_FAIL";

export const PRODUCT_QUERY_LIST_REQUEST = "PRODUCT_QUERY_LIST_REQUEST";
export const PRODUCT_QUERY_LIST_SUCCESS = "PRODUCT_QUERY_LIST_SUCCESS";
export const PRODUCT_QUERY_LIST_FAIL = "PRODUCT_QUERY_LIST_FAIL";

export const VIDEO_QUERY_LIST_REQUEST = "VIDEO_QUERY_LIST_REQUEST";
export const VIDEO_QUERY_LIST_SUCCESS = "VIDEO_QUERY_LIST_SUCCESS";
export const VIDEO_QUERY_LIST_FAIL = "VIDEO_QUERY_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_ALLTOPIC_CREATE_REQUEST = "PRODUCT_REVIEW_CREATE_REQUEST";
export const PRODUCT_ALLTOPIC_CREATE_SUCCESS = "PRODUCT_REVIEW_CREATE_SUCCESS";
export const PRODUCT_ALLTOPIC_CREATE_FAIL = "PRODUCT_REVIEW_CREATE_FAIL";
export const PRODUCT_ALLTOPIC_CREATE_RESET = "PRODUCT_REVIEW_CREATE_RESET";

export const PRODUCT_REVIEW_CREATE_REQUEST = "PRODUCT_REVIEW_CREATE_REQUEST";
export const PRODUCT_REVIEW_CREATE_SUCCESS = "PRODUCT_REVIEW_CREATE_SUCCESS";
export const PRODUCT_REVIEW_CREATE_FAIL = "PRODUCT_REVIEW_CREATE_FAIL";
export const PRODUCT_REVIEW_CREATE_RESET = "PRODUCT_REVIEW_CREATE_RESET";

export const PRODUCT_REVIEW_DELETE_REQUEST = "PRODUCT_REVIEW_DELETE_REQUEST";
export const PRODUCT_REVIEW_DELETE_SUCCESS = "PRODUCT_REVIEW_DELETE_SUCCESS";
export const PRODUCT_REVIEW_DELETE_FAIL = "PRODUCT_REVIEW_DELETE_FAIL";
export const PRODUCT_REVIEW_DELETE_RESET = "PRODUCT_REVIEW_DELETE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

export const PRODUCT_CATEGORY_LIST_REQUEST = "PRODUCT_CATEGORY_LIST_REQUEST";
export const PRODUCT_CATEGORY_LIST_SUCCESS = "PRODUCT_CATEGORY_LIST_SUCCESS";
export const PRODUCT_CATEGORY_LIST_FAIL = "PRODUCT_CATEGORY_LIST_FAIL";

export const PRODUCT_COUNTRY_LIST_REQUEST = "PRODUCT_COUNTRY_LIST_REQUEST";
export const PRODUCT_COUNTRY_LIST_SUCCESS = "PRODUCT_COUNTRY_LIST_SUCCESS";
export const PRODUCT_COUNTRY_LIST_FAIL = "PRODUCT_COUNTRY_LIST_FAIL";

export const PRODUCT_LANGUAGE_LIST_REQUEST = "PRODUCT_LANGUAGE_LIST_REQUEST";
export const PRODUCT_LANGUAGE_LIST_SUCCESS = "PRODUCT_LANGUAGE_LIST_SUCCESS";
export const PRODUCT_LANGUAGE_LIST_FAIL = "PRODUCT_LANGUAGE_LIST_FAIL";

export const PRODUCT_MOOD_LIST_REQUEST = "PRODUCT_MOOD_LIST_REQUEST";
export const PRODUCT_MOOD_LIST_SUCCESS = "PRODUCT_MOOD_LIST_SUCCESS";
export const PRODUCT_MOOD_LIST_FAIL = "PRODUCT_MOOD_LIST_FAIL";

export const PRODUCT_CONTEXT_LIST_REQUEST = "PRODUCT_CONTEXT_LIST_REQUEST";
export const PRODUCT_CONTEXT_LIST_SUCCESS = "PRODUCT_CONTEXT_LIST_SUCCESS";
export const PRODUCT_CONTEXT_LIST_FAIL = "PRODUCT_CONTEXT_LIST_FAIL";

export const PRODUCT_LEVEL_LIST_REQUEST = "PRODUCT_LEVEL_LIST_REQUEST";
export const PRODUCT_LEVEL_LIST_SUCCESS = "PRODUCT_LEVEL_LIST_SUCCESS";
export const PRODUCT_LEVEL_LIST_FAIL = "PRODUCT_LEVEL_LIST_FAIL";

export const PRODUCT_TAG_LIST_REQUEST = "PRODUCT_TAG_LIST_REQUEST";
export const PRODUCT_TAG_LIST_SUCCESS = "PRODUCT_TAG_LIST_SUCCESS";
export const PRODUCT_TAG_LIST_FAIL = "PRODUCT_TAG_LIST_FAIL";

export const PRODUCT_PLATFORM_LIST_REQUEST = "PRODUCT_PLATFORM_LIST_REQUEST";
export const PRODUCT_PLATFORM_LIST_SUCCESS = "PRODUCT_PLATFORM_LIST_SUCCESS";
export const PRODUCT_PLATFORM_LIST_FAIL = "PRODUCT_PLATFORM_LIST_FAIL";

export const PRODUCT_ALLTOPICS_LIST_REQUEST = "PRODUCT_ALLTOPICS_LIST_REQUEST";
export const PRODUCT_ALLTOPICS_LIST_SUCCESS = "PRODUCT_ALLTOPICS_LIST_SUCCESS";
export const PRODUCT_ALLTOPICS_LIST_FAIL = "PRODUCT_ALLTOPICS_LIST_FAIL";

export const PRODUCT_SUBTOPIC_LIST_REQUEST = "PRODUCT_SUBTOPIC_LIST_REQUEST";
export const PRODUCT_SUBTOPIC_LIST_SUCCESS = "PRODUCT_SUBTOPIC_LIST_SUCCESS";
export const PRODUCT_SUBTOPIC_LIST_FAIL = "PRODUCT_SUBTOPIC_LIST_FAIL";

/*
export const PLAYLIST_PRODUCT_LIST_REQUEST = "PLAYLIST_PRODUCT_LIST_REQUEST";
export const PLAYLIST_PRODUCT_LIST_SUCCESS = "PPLAYLIST_PRODUCT_LIST_SUCCESS";
export const PLAYLIST_PRODUCT_LIST_FAIL = "PLAYLIST_PRODUCT_LIST_FAIL"; */
