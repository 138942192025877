import React, { useEffect, useState } from "react";
import { Link } from "../../node_modules/react-router-dom/index";
import moment from "../../node_modules/moment/moment";

export default function ThumbnailVideo(props) {
  const { c, sendReco, setVideoPlayed, setChannelPlayed } = props;

  const [imageExist, setImageExist] = useState(true);

  // Function to remove the videos that don't exist anymore

  const getImageHeight = (url, callback) => {
    let img = new Image();
    img.onload = () => callback(img.height);
    img.onerror = () => callback(null);
    img.src = url;
  };

  useEffect(() => {
    setImageExist(true);
    getImageHeight(c.lastVideos.thumbnail, (height) => {
      if (height !== null) {
        if (height == 90) {
          setImageExist(false);
        }
      }
    });
  }, [c]);

  return (
    <div className="thumbnailWrapper">
      {imageExist && (
        <div className="videoThumbnailsBrowse">
          <div className="alignVertical">
            <div className="thumbnailContainer">
              <a className="durationInfo">{c.lastVideos.duration}min</a>
              <img
                loading="lazy"
                src={c.lastVideos.thumbnail}
                className="browseMedium"
                onClick={() => {
                  setVideoPlayed(c.lastVideos);
                  setChannelPlayed([c.channelName, c._id]);
                }}
                alt={c.lastVideos.title + " video thumbnail"}
              />
              <div className="thumbnailHover">
                <i
                  className="fa-solid fa-play"
                  id="toPlay"
                  onClick={() => {
                    setVideoPlayed(c.lastVideos);
                    setChannelPlayed([c.channelName, c._id]);
                  }}
                >
                  {" "}
                  <div
                    className="rateVideo"
                    id={"thumbs" + c.lastVideos.title.substring(0, 12)}
                  >
                    <i
                      className="fa-regular fa-thumbs-up"
                      title="Good recommendation"
                      onClick={(e) => {
                        e.stopPropagation();
                        sendReco(c.channelName, c.lastVideos.title, true);
                      }}
                    ></i>
                    <i
                      className="fa-regular fa-thumbs-down"
                      title="Bad recommendation"
                      onClick={(e) => {
                        e.stopPropagation();
                        sendReco(c.channelName, c.lastVideos.title, false);
                      }}
                    ></i>{" "}
                  </div>
                </i>
              </div>
            </div>
            <a
              onClick={() => {
                setVideoPlayed(c.lastVideos);
                setChannelPlayed([c.channelName, c._id]);
              }}
              id="moreSpaceUp"
            >
              {c.lastVideos.title.split(/\s+/).slice(0, 15).join(" ")}
              {c.lastVideos.title.match(/(\w+)/g) &&
              c.lastVideos.title.match(/(\w+)/g).length > 15
                ? "..."
                : ""}
            </a>

            <Link
              to={`/channel/${c.channelName
                .replaceAll(" ", "_")
                .replaceAll("-", "_")
                .toLowerCase()}-${c._id}`}
            >
              <text>{c.channelName}</text> <text>-</text>{" "}
              <text>
                {c.lastVideos.nbViews > 1000000
                  ? Math.round(c.lastVideos.nbViews / 100000) / 10 + "M"
                  : c.lastVideos.nbViews > 10000
                  ? Math.round(c.lastVideos.nbViews / 1000) + "K"
                  : c.lastVideos.nbViews > 1000
                  ? Math.round(c.lastVideos.nbViews / 100) / 10 + "K"
                  : c.lastVideos.nbViews}{" "}
                views
              </text>{" "}
              <text>-</text>{" "}
              <text>{moment(c.lastVideos.publishDate).fromNow()}</text>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
