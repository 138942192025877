import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../actions/userActions";
import { useAuth } from "../AuthContext";
import { useHistory } from "../../node_modules/react-router-dom/index";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function RegisterScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { signup, loginGmail } = useAuth();
  const dispatch = useDispatch();

  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";

  /* 
  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;  */

  /* const dispatch = useDispatch(); */
  async function submitHandler(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setErrorLogin("Passwords do not match");
    } else if (password.length < 8) {
      return setErrorLogin(
        "Your password needs to be at least 8 characters long"
      );
    } else if (name.length < 5) {
      return setErrorLogin("Your name needs to be at least 5 characters long");
    }

    setLoadingLogin(true);
    setErrorLogin("");

    try {
      const result = await signup(email, password);
      await result.user
        .sendEmailVerification()
        .then(() => {
          console.log("Verification email sent.");
        })
        .catch((error) => {
          console.error("Error sending verification email");
        });

      const uid = result.user.uid;
      const token = await result.user.getIdToken();

      const registerUser = async () => {
        try {
          await dispatch(register(name, uid, token));
          history.push("/signedin");
        } catch (error) {
          console.error("Error creating user");
          throw error; // Rethrow to handle retry logic
        }
      };

      // Retry mechanism with exponential backoff
      const maxRetries = 3;
      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          await registerUser();
          break; // Exit loop if successful
        } catch (error) {
          if (attempt === maxRetries) {
            throw error; // Rethrow after final attempt
          }
          await new Promise((res) => setTimeout(res, attempt * 1000)); // Exponential backoff
        }
      }
    } catch (error) {
      setErrorLogin("Failed to create an account");
      console.error("Registration error");
    } finally {
      setLoadingLogin(false);
    }
  }

  async function loginGmailHandler(e) {
    e.preventDefault();
    setLoadingLogin(true);
    setErrorLogin("");

    try {
      const result = await loginGmail();
      const uid = result.user.uid;
      const name =
        result.user.displayName ||
        `User${Math.floor(10000 + Math.random() * 90000)}`;
      const token = await result.user.getIdToken();

      const registerUser = async () => {
        try {
          await dispatch(register(name, uid, token));
          history.push("/signedin");
        } catch (error) {
          console.error("Error creating user");
          throw error; // Rethrow to handle retry logic
        }
      };

      // Retry mechanism with exponential backoff
      const maxRetries = 3;
      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          await registerUser();
          break; // Exit loop if successful
        } catch (error) {
          if (attempt === maxRetries) {
            throw error; // Rethrow after final attempt
          }
          await new Promise((res) => setTimeout(res, attempt * 1000)); // Exponential backoff
        }
      }
    } catch (error) {
      setErrorLogin("Failed to create an account");
      console.error("Registration error");
    } finally {
      setLoadingLogin(false);
    }
  }

  return (
    <div className="mainElement">
      <Helmet>
        <title>Sign Up | Favoree</title>
        <meta name="description" content="Sign Up for Favoree"></meta>
        <link rel="canonical" href="https://www.favoree.io/register"></link>
      </Helmet>
      <div className="block-review">
        <div className="fiftyPercent">
          <div className="space"> </div>
          <div className="space"> </div>
          <h2>Sign up</h2>

          {errorLogin && <text className="error">{errorLogin}</text>}

          <button onClick={loginGmailHandler} className="googleConnect">
            {" "}
            <img
              className="googleIcon"
              src="/images/googleIcon.png"
              alt="Google Icon"
            />
            Sign up with Google
          </button>
          <div className="hr-line">
            <hr></hr>
          </div>
          <form className="form" onSubmit={submitHandler}>
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter name"
                required
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                placeholder="Enter email address"
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter password"
                required
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="confirmPassword">Confirm password</label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label />
              <button disable={loadingLogin} className="primary" type="submit">
                Sign up
              </button>
            </div>
            <div>
              <label />
            </div>
          </form>

          <div className="hr-line">
            <hr></hr>
          </div>
          <div className="space"></div>

          <div>
            You already have an account?{" "}
            <Link to={`/signin?redirect=${redirect}`}>Sign in</Link>
          </div>
          <div className="space"></div>
          <div className="space"></div>

          <div>Trouble logging in?</div>
          <div>Contact us: contact@favoree.io</div>
        </div>
        <div className="fiftyPercent"></div>
        <div className="fiftyPercent">
          <div className="space"></div>
          <div className="space"></div>
          <div className="popup-inner">
            <h2>What's the point to sign up ?</h2>
            <div className="space"></div>
            <text>{"  "}⭐ Give channel ratings</text>
            <div className="space"></div>
            <text> ✍️ Write channel reviews</text>
            <div className="space"></div>
            <text> 💖 Create a watchlist of all your favorite channels</text>
            <div className="space"></div>
            <text> 🆕 Add new missing channels to Favoree</text>
            <div className="space"></div>
            <text> 🧡 Get a top of similar channels to your favorite ones</text>

            <div className="space"></div>
            <text> ⛔ Free and 100% “no SPAM” Guarantee</text>
            <div className="space"></div>
            <div className="space"></div>
            <img
              className="img-popup"
              src="/images/addChannel.png"
              alt="Preview of Favoree's watchlist"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
