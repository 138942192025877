import React, { useState } from "react";

export default function SearchBox(props) {
  const [name, setName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();

    props.history.push(`/search/results/${name && name != " " ? name : "no"}`);
    /* if (window.location.href.split("search/")[1].substring(0, 3) == "res") {
      window.location.reload();
    }   */
    function toggleOffer() {
      if (document.getElementById("checkboxMenu")) {
        let toggleButton = document.getElementById("checkboxMenu");
        toggleButton.checked = false;
      }
    }
    toggleOffer();
  };

  return (
    <form className="search" onSubmit={submitHandler}>
      <div className="row">
        <input
          type="text"
          name="q"
          id="q"
          placeholder="Channel, topic, video title..."
          onChange={(e) => setName(e.target.value)}
        ></input>
        <button className="primary" type="submit">
          <i className="fa fa-search"></i>
        </button>
      </div>
    </form>
  );
}
