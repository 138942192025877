import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsUser, updateUserProfile } from "../actions/userActions";
import { useAuth } from "../AuthContext";
import { Link } from "../../node_modules/react-router-dom/index";
import LoadingBox from "../components/LoadingBox";

export default function ProfileScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const {
    currentUser,
    updatePassword,
    updateEmail,
    userName,
    uploadProfilePicture,
    removeProfilePicture,
  } = useAuth();
  const [name, setName] = useState(currentUser.displayName);
  const [description, setDescription] = useState(currentUser.description || "");
  const [twitter, setTwitter] = useState(currentUser.twitter || "");
  const [youtube, setYoutube] = useState(currentUser.youtube || "");
  const [photoURL, setPhotoURL] = useState(
    "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media"
  );
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [triggerPopUp, setTriggerPopUp] = useState(null);

  /* const detailsUser = useSelector((state) => state.detailsUser);  */
  /*  const { user } = detailsUser;  */

  /*
  if (currentUser) {
    dispatch(detailsUser(currentUser.auth.currentUser.accessToken));
  }  */
  /*
  const detailsUserUser = useSelector((state) => state.detailsUser);
  /* const { user } = detailsUser;  */

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, [currentUser]);

  function handleChangePicture(e) {
    setLoadingPicture(true);
    if (e.target.files[0]) {
      uploadProfilePicture(e.target.files[0]).then((result) => {
        if (currentUser?.photoURL) {
          setPhotoURL(currentUser.photoURL);
        }
        setLoadingPicture(false);
      });
    }
  }

  function clickRemovePicture(e) {
    e.preventDefault();
    if (currentUser?.photoURL) {
      removeProfilePicture().then((result) => {
        if (currentUser?.photoURL) {
          setPhotoURL(null);
        }
      });
    }
  }

  /*const detailsUser = useSelector((state) => state.detailsUser);*
  /* const { test } = detailsUser;*/

  /*
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile; */

  /*
  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo._id));
    } else {
      setName(user.name);
      setEmail(user.email);
    }
  }, [dispatch, userInfo._id, user]);  */

  async function submitHandler(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setErrorLogin("Passwords do not match");
    }

    if (password && password.length < 8) {
      return setErrorLogin(
        "Your password needs to be at least 8 characters long"
      );
    }

    if (
      twitter &&
      twitter != " " && // to erase user handle
      twitter != "  " &&
      (twitter.charAt(0) != "@" || twitter.length < 4 || twitter.length > 17)
    ) {
      return setErrorLogin(
        "Your twitter handle needs to be at least 4 characters long and start with '@' "
      );
    }

    if (
      youtube &&
      youtube != " " && // to erase user handle
      youtube != "  " &&
      (youtube.charAt(0) != "@" || youtube.length < 4 || youtube.length > 30)
    ) {
      return setErrorLogin(
        "Your YouTube handle needs to be at least 4 characters long and start with '@' "
      );
    }

    if (description && description.length > 100) {
      return setErrorLogin("Your description cannot exceed 100 characters");
    }

    const promises = [];
    setLoadingLogin(true);
    setErrorLogin("");

    if (email !== currentUser.email) {
      promises.push(updateEmail(email));
    }
    if (password !== currentUser.password) {
      promises.push(updatePassword(password));
    }

    const token = currentUser.auth.currentUser.accessToken;

    const updatedProfile = {};

    if (name !== currentUser.displayName) {
      updatedProfile.name = name;
    }

    if (description != "" && description !== currentUser.description) {
      updatedProfile.description = description;
    }

    if (twitter != "" && twitter !== currentUser.twitter) {
      updatedProfile.twitter = twitter;
    }

    if (youtube != "" && youtube !== currentUser.youtube) {
      updatedProfile.youtube = youtube;
    }

    if (token) {
      updatedProfile.token = token;
    }

    if (Object.keys(updatedProfile).length > 1) {
      dispatch(updateUserProfile(updatedProfile));
    }

    Promise.all(promises)
      .then(() => setMessage("Account successfully updated ✅"))
      .catch((error) =>
        setErrorLogin(
          "Failed to update account. " +
            error
              .toString()
              .replace("FirebaseError: Firebase: ", "")
              .replace("(auth/requires-recent-login).", "")
        )
      )
      .finally(() => {
        setLoadingLogin(false);
      });
  }

  // Retrieving profile picture from the user and not selecting if it comes directly from his GMAIL account
  useEffect(() => {
    if (
      currentUser?.photoURL &&
      currentUser.photoURL.includes("firebasestorage")
    ) {
      setPhotoURL(currentUser.photoURL);
    }
  }, []);

  /*
  function test() {
    ".backup_picture".on("error", function () {
      this.attr(
        "src",
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
      );
    });
  }  */

  return (
    <div className="mainElement">
      <form className="form" onSubmit={submitHandler} id="settings">
        <div>
          <h2>Update user account </h2>
        </div>

        {user && user.userName ? (
          <div>
            {currentUser?.photoURL ? (
              <div>
                <img
                  src={
                    photoURL != null
                      ? photoURL
                      : `https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media`
                  }
                  onError={(e) => {
                    if (
                      !e.target.src.endsWith(
                        "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media"
                      )
                    ) {
                      e.target.src =
                        "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media";
                    }
                  }}
                  alt="User Profile Picture"
                  className="avatarPictureMedium"
                />
              </div>
            ) : (
              <div className="profileRound">
                <i className="fa-solid fa-user"></i>{" "}
              </div>
            )}
            <div className="space"></div>

            <button type="button" onClick={() => setTriggerPopUp(true)}>
              Update avatar
            </button>
            <div className="space"></div>
            <div className="space"></div>

            {triggerPopUp && (
              <div className="popup">
                <div className="popup-inner">
                  <div className="alignVertical">
                    <h2>Change avatar</h2>
                    <img
                      src={
                        photoURL != null
                          ? photoURL
                          : `https://%2F${photoURL}?alt=media`
                      }
                      onError={(e) => {
                        if (
                          !e.target.src.endsWith(
                            "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media"
                          )
                        ) {
                          e.target.src =
                            "https://firebasestorage.googleapis.com/v0/b/project-react-93635.appspot.com/o/profile_pictures%2FprofilePicture.png?alt=media";
                        }
                      }}
                      alt="User Profile Picture"
                      className="avatarPictureMedium"
                    />
                    <div className="space"></div>
                    <label class="custom-file-upload">
                      <input
                        type="file"
                        onChange={handleChangePicture}
                        id="uploadPicture"
                        accept="image/*"
                      ></input>
                    </label>
                    {loadingPicture && <LoadingBox></LoadingBox>}{" "}
                    <div className="space"></div>
                    <p>(Image must be less than 1MB)</p>
                    {currentUser?.photoURL && (
                      <div>
                        <button
                          type="button"
                          onClick={() => setTriggerPopUp(false)}
                          className="buttonCheck"
                        >
                          <i class="fa-solid fa-check"></i> Confirm
                        </button>
                        <button
                          type="button"
                          onClick={clickRemovePicture}
                          className="buttondelete"
                          id="darkBlue"
                        >
                          <i class="fa-regular fa-trash-can"></i> Remove picture
                        </button>
                      </div>
                    )}
                  </div>
                  <i
                    onClick={() => setTriggerPopUp(false)}
                    className="fa-solid fa-xmark"
                  ></i>
                </div>
              </div>
            )}
            {errorLogin && <text className="error">{errorLogin}</text>}
            {message && (
              <div>
                {" "}
                <text className="success">{message}</text>
              </div>
            )}
            <label htmlFor="name"> Name </label>
            <input
              id="name"
              type="name"
              placeholder={user.userName || name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <label htmlFor="twitter">
            {" "}
            Twitter account <i class="fa-brands fa-twitter"></i>
          </label>
          <input
            id="twitter"
            type="twitter"
            placeholder={
              user && user.twitter ? user.twitter : "@YourTwitterHandle"
            }
            onChange={(e) => setTwitter(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="youtube">
            {" "}
            YouTube account <i class="fa-brands fa-youtube"></i>
          </label>
          <input
            id="youtube"
            type="youtube"
            placeholder={
              user && user.youtube ? user.youtube : "@YourYouTubeHandle"
            }
            onChange={(e) => setYoutube(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="description"> Bio </label>
          <textarea
            id="description"
            type="description"
            placeholder={
              user && user.description
                ? user.description
                : "Add a small description, emojis accepted 🔥"
            }
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label htmlFor="email"> Email </label>
          <input
            id="email"
            type="email"
            placeholder={currentUser.email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="password"> New password </label>
          <input
            id="password"
            type="password"
            placeholder="Leave blank to keep the same"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="confirmPassword"> Confirm password </label>
          <input
            id="password"
            type="password"
            placeholder="Leave blank to keep the same"
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label />
          <button disable={loadingLogin} className="primary" type="submit">
            Update
          </button>
        </div>
        <div className="space"></div>

        <div>
          <Link to="/">Cancel</Link>
        </div>
      </form>
    </div>
  );
}
