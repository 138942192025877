export const platformLogos = ["/images/Youtube.png"];

/*

,
  "/images/Amazonmusic.png",
  "/images/Deezer.png",
  "/images/Spotify.png",
  "/images/Googlepodcast.png",
  "/images/Applepodcast.png",

  */
