import React, { useState } from "react";

export default function ChannelDescription(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li>
      <div
        style={{
          maxHeight: isExpanded
            ? "none"
            : props.product.description != "No description for this channel" &&
              props.product.description.length < 95
            ? "80px"
            : props.product.description.length < 220
            ? "90px"
            : "140px",
          overflow: "hidden",
          position: "relative",
          transition: "max-height 0.3s ease",
          paddingRight: "1em", // Adjust for scrollbar if present
        }}
      >
        {props.product.description != "No description for this channel" &&
          props.product.description.length > 20 && (
            <div>
              <h3> Description </h3>
              <p>{props.product.description.replace(/\\n/g, "<br />")}</p>
            </div>
          )}
        {props.product.allTopics[0] && (
          <div>
            {" "}
            <h3>Channel details</h3>
            <p>
              The main content on {props.product.name} is related to{" "}
              {props.product.allTopics[0].mainTopic}. The channel has{" "}
              {props.product.subscriberCount} subscribers and has reached{" "}
              {Math.floor(props.product.viewCount / 1000) * 1000} video views in
              total.
            </p>{" "}
          </div>
        )}

        {props.similarProgramsSameTopic && props.similarProgramsSameTopic[0] ? (
          <div>
            <h3>YouTube channels like {props.product.name}</h3>
            <p>
              The 10 most similar YouTubers to {props.product.name} are: <br />
              {props.similarProgramsSameTopic.map(
                (product, index) => product.name + (index < 9 ? ", " : ".")
              )}
            </p>
          </div>
        ) : (
          <div> </div>
        )}
        {!isExpanded && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "30px", // Height of the fade-out area
              background:
                "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(12, 12, 14) 100%)",
            }}
          />
        )}
      </div>
      {(props.product.description == "No description for this channel" &&
        !props.similarProgramsSameTopic[0]) ||
      isExpanded ? (
        <div></div>
      ) : (
        <button
          class="moreFilters"
          onClick={() => setIsExpanded(true)}
          style={{
            "margin-top": "-1rem",
            "margin-bottom": "2rem",
            "background-color": "transparent",
          }}
        >
          <i class="fa-solid fa-chevron-down"></i> Read more
        </button>
      )}
    </li>
  );
}
