import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "../../node_modules/react-router-dom/index";
import { getDetailsUser } from "../actions/userActions";
import { useAuth } from "../AuthContext";
import PopUpMessage from "../components/PopUpMessage";

export default function SignedInScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [alertEmailValidationResent, setAlertEmailValidationResent] =
    useState(false);

  const dispatch = useDispatch();

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, [currentUser]);

  const handleResendEmailVerification = () => {
    if (currentUser && !currentUser._delegate.emailVerified) {
      currentUser
        .sendEmailVerification()
        .then(() => {
          setAlertEmailValidationResent(true);
        })
        .catch((error) => {
          console.error("Error sending email verification", error);
          alert("Error sending verification email. Please try again later.");
        });
    }
  };

  return (
    <div className="mainElement">
      <div className="form">
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        {!currentUser?._delegate.emailVerified ? (
          <div>
            <h1>Welcome to Favoree, one last step! 😉</h1>
            <div className="space"></div>
            <div className="card card-body3">
              <div className="space"></div>

              <strong>✅ A verification link has been sent to:</strong>
              <div className="space"></div>

              <text>{currentUser?._delegate.email}</text>
              <div className="space"></div>

              <div className="space"></div>
            </div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <text>If you didn't receive our email:</text>
            <div className="space"></div>
            <button onClick={handleResendEmailVerification}>
              Resent verification link
            </button>{" "}
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <text>
              If your email address is incorrect, simply{" "}
              <Link to={`/register`}> create a new account</Link>.
            </text>
            <PopUpMessage
              message="Verification email sent.
              Please check your inbox."
              type="check"
              trigger={alertEmailValidationResent}
              setTrigger={setAlertEmailValidationResent}
            ></PopUpMessage>
          </div>
        ) : (
          <div>
            <h1
              style={{
                "text-align": "center",
              }}
            >
              You are signed in ✅
            </h1>
            <div className="space"></div>
            <div className="space"></div>
            <img
              className="imageSubmitted"
              src="/images/Channel-Cards-Rating.png"
              alt="Favoree Review"
              style={{
                width: "300px",
                "max-width": "80%",
                display: "block",
              }}
            ></img>
            <div className="space"></div>
            <h2
              style={{
                "text-align": "center",
              }}
            >
              Welcome! You can now rate and review YouTube channels ❤️‍🔥
            </h2>
            <p
              style={{
                "text-align": "center",
              }}
            >
              We hope you enjoy using Favoree.
            </p>{" "}
          </div>
        )}
      </div>
    </div>
  );
}
