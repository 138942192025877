export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_PLAYLIST2_REQUEST = "USER_PLAYLIST2_REQUEST";
export const USER_PLAYLIST2_SUCCESS = "USER_PLAYLIST2_SUCCESS";
export const USER_PLAYLIST2_FAIL = "USER_PLAYLIST2_FAIL";
export const USER_PLAYLIST2_RESET = "USER_PLAYLIST2_RESET";

export const USER_PLAYLIST_LIST_REQUEST = "USER_PLAYLIST_LIST_REQUEST";
export const USER_PLAYLIST_LIST_SUCCESS = "USER_PLAYLIST_LIST_SUCCESS";
export const USER_PLAYLIST_LIST_FAIL = "USER_PLAYLIST_LIST_FAIL";
export const USER_PLAYLIST_LIST_RESET = "USER_PLAYLIST_LIST_RESET";

export const USER_REMOVE_PLAYLIST_REQUEST = "USER_REMOVE_PLAYLIST_REQUEST";
export const USER_REMOVE_PLAYLIST_SUCCESS = "USER_REMOVE_PLAYLIST_SUCCESS";
export const USER_REMOVE_PLAYLIST_FAIL = "USER_REMOVE_PLAYLIST_FAIL";
export const USER_REMOVE_PLAYLIST_RESET = "USER_REMOVE_PLAYLIST_RESET";

export const USER_QUERY_LIST_REQUEST = "USER_QUERY_LIST_REQUEST";
export const USER_QUERY_LIST_SUCCESS = "USER_QUERY_LIST_SUCCESS";
export const USER_QUERY_LIST_FAIL = "USER_QUERY_LIST_FAIL";
