import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { useAuth } from "../AuthContext";
import { register } from "../actions/userActions";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function SigninScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorLogin, setErrorLogin] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);

  const { login, loginGmail, currentUser } = useAuth();

  const dispatch = useDispatch();

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/signedin";

  /* 
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;  */

  async function submitHandler(e) {
    e.preventDefault();

    try {
      setErrorLogin("");
      setLoadingLogin(true);
      await login(email, password).then((result) => {
        props.history.push("/");
      });
    } catch (err) {
      if (err.code == "auth/user-not-found") {
        setErrorLogin("User mail not found");
      } else if (err.code == "auth/wrong-password") {
        setErrorLogin("Wrong password");
      } else {
        setErrorLogin("Failed to sign in");
      }
    }
    setLoadingLogin(false);
  }

  async function loginGmailHandler(e) {
    e.preventDefault();

    try {
      setErrorLogin("");
      setLoadingLogin(true);
      await loginGmail().then((result) => {
        const uid = result.user.uid;
        const name = result.user.displayName;
        result.user.getIdToken().then((token) => {
          dispatch(register(name, uid, token)).then(() => {
            props.history.push("/");
          });
        });
      });
      /* dispatch(register(name, email, password))  */
    } catch {
      setErrorLogin("Failed to create an account");
    }

    setLoadingLogin(false);
  }

  /*
  useEffect(() => {
    if (userInfo) {
      props.history.push("/");
    }
  }, [props.history, redirect, userInfo]);  */
  return (
    <div className="mainElement">
      <Helmet>
        <title>Sign In | Favoree</title>
        <meta name="description" content="Log into Favoree"></meta>
        <link rel="canonical" href="https://www.favoree.io/signin"></link>
      </Helmet>
      <div className="block-review">
        <div className="fiftyPercent">
          <div className="space"></div>
          <div>
            <h2>Sign in</h2>
          </div>

          {errorLogin && <text className="error">{errorLogin}</text>}
          <button onClick={loginGmailHandler} className="googleConnect">
            {" "}
            <img
              className="googleIcon"
              src="/images/googleIcon.png"
              alt="Google Icon"
            />
            Sign in with Google
          </button>
          <div className="hr-line">
            <hr></hr>
          </div>
          <form className="form" onSubmit={submitHandler}>
            <div>
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                placeholder="Please enter your email address"
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Please enter your password"
                required
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label />
              <button disable={loadingLogin} className="primary" type="submit">
                Sign in
              </button>
            </div>
            <div className="space"></div>

            <div>
              <Link to={`/forgot-password`}>Forgot password?</Link>
            </div>
            <div className="space"></div>

            <div className="hr-line">
              <hr></hr>
            </div>
            <div>
              <label />
              <div className="space"></div>

              <div>
                New to Favoree? {""}
                <Link to={`/register?redirect=${redirect}`}>Sign up</Link>, it's
                free
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
