import Axios from "axios";
import {
  PRODUCT_REVIEW_CREATE_FAIL,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_REVIEW_DELETE_FAIL,
  PRODUCT_REVIEW_DELETE_SUCCESS,
} from "../constants/productConstants";
import {
  REVIEWS_PROGRAM_FAIL,
  REVIEWS_PROGRAM_REQUEST,
  REVIEWS_PROGRAM_SUCCESS,
} from "../constants/reviewConstants";

export const reviewsFromProgram = (programId) => async (dispatch) => {
  dispatch({
    type: REVIEWS_PROGRAM_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      process.env.REACT_APP_API_ADDRESS + `reviews/program/${programId}`
    );
    dispatch({ type: REVIEWS_PROGRAM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: REVIEWS_PROGRAM_FAIL, payload: error.message });
  }
};

export const deleteReview =
  (productId, token) => async (dispatch, getState) => {
    try {
      const { data } = Axios.delete(
        process.env.REACT_APP_API_ADDRESS + `products/${productId}/reviews`,
        { data: { token } }
      );

      const { data2 } = Axios.delete(
        process.env.REACT_APP_API_ADDRESS + `reviews/${productId}/reviews`,
        { data: { token } }
      );
      dispatch({ type: PRODUCT_REVIEW_DELETE_SUCCESS, payload: "removed" });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_REVIEW_DELETE_FAIL, payload: message });
    }
  };

/* dispatch({ type: PRODUCT_REVIEW_CREATE_REQUEST });
    const {
      userSignin: { userInfo },  
    } = getState(); */

export const createReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_API_ADDRESS + `products/${productId}/reviews`,
        review
      );
      const { data2 } = await Axios.post(
        process.env.REACT_APP_API_ADDRESS + `reviews/${productId}/reviews`,
        review
      );

      dispatch({
        type: PRODUCT_REVIEW_CREATE_SUCCESS,
        payload: data.review,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload: message });
    }
  };
